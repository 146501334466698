/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostReviewCardMutation,
  usePatchReviewCardMutation,
  useDeleteReviewCardMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { isAllowed } from 'helpers/isAllowed'
import DateUtils from 'helpers/DateUtils'
import { useAppDispatch } from 'store/hooks'
import { setReviewOpen } from 'store/slices/reviewSlice'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import Avatar from 'components/Avatar/Avatar'
import ReviewsCardModalComment from './ReviewsCardModalComment'
import ReviewsCardModalCommentAddEdit from './ReviewsCardModalCommentAddEdit'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ReviewCardStoreValidator,
  ReviewCard,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const reviewCardBulkSchema = Yup.object<Shape<ReviewCardStoreValidator>>({
  title: Yup.string().required('Le titre est obligatoire'),
  description: Yup.string().nullable(),
}).required()

type ReviewCardRequest = FormikContextType<ReviewCardStoreValidator>

/* Styled components -------------------------------------------------------- */
const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  min-width: 150px;
  height: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

const Info = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
  font-size: .8rem;
  align-items: center;
`

const AddCommentContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`

const CommentsContainer = styled.div`
  max-height: 30vh;
  overflow-y: auto;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsCardModalProps {
  reviewCard?: ReviewCard;
  reviewBoardUserId: number;
  reviewBoardId: number;
  reviewBoardCategoryId: number;
  handleClose: () => void;
}

const ReviewsCardModal: React.FC<ReviewsCardModalProps> = ({
  handleClose,
  reviewBoardUserId,
  reviewCard,
  reviewBoardId,
  reviewBoardCategoryId,
}) => {
  const dispatch = useAppDispatch()
  const [ addComment, setAddComment ] = useState<boolean>(false)

  const [
    submitNewReviewCard,
  ] = usePostReviewCardMutation()
  const [
    submitEditReviewCard,
  ] = usePatchReviewCardMutation()
  const [
    submitDeleteReviewCard,
    { isLoading: isDeletingReviewCard },
  ] = useDeleteReviewCardMutation()

  const onSubmit = (values: ReviewCardStoreValidator, { setSubmitting, resetForm }: FormikHelpers<ReviewCardStoreValidator>) => {
    if (reviewCard) {
      submitEditReviewCard({ id: reviewCard.id, data: values, reviewBoardId })
        .then((response) => {
          if (!isApiError(response)) {
            resetForm()
            handleClose()
          } else {
            toast.error('Une erreur est survenue.')
            setSubmitting(false)
          }
        }).catch(console.error)
    } else {
      submitNewReviewCard({ reviewBoardId, data: { ...values, reviewBoardCategoryId }})
        .then((response) => {
          if (!isApiError(response)) {
            resetForm()
            handleClose()
          } else {
            toast.error('Une erreur est survenue.')
            setSubmitting(false)
          }
        }).catch(console.error)
    }
  }

  const formikForm: ReviewCardRequest = useForm<ReviewCardStoreValidator>(
    {
      initialValues: {
        title: '',
        description: '',
        reviewBoardCategoryId,
      },
      onSubmit: onSubmit,
      validationSchema: reviewCardBulkSchema,
    },
  )

  useEffect(() => {
    if (reviewCard) {
      formikForm.setValues(reviewCard)
    }
  }, [ reviewCard ])

  const onDeleteCard = async () => {
    if (!reviewCard) return
    await submitDeleteReviewCard({ id: reviewCard.id, reviewBoardId: reviewCard.reviewBoardId })
    handleClose()
  }

  const allowRefresh = () => {
    dispatch(setReviewOpen(false))
    const timer = setTimeout(() => dispatch(setReviewOpen(true)), 3000)
    return () => clearTimeout(timer)
  }

  const onAddCommentClose = () => {
    setAddComment(false)
    allowRefresh()
  }

  const displayLastEdit = (createdAt: string, updatedAt: string, fem: boolean = false): React.ReactNode => {
    return (
      <React.Fragment>
        {` le `}
        <b>
          {DateUtils.APIStrToLocalDateString(createdAt)}
        </b>
        {` à `}
        <b>
          {DateUtils.APIStrToLocalTimeString(createdAt, { hour: '2-digit', minute: '2-digit' })}
        </b>
        .
        {
          createdAt !== updatedAt &&
            <React.Fragment>
              {` Modifié${fem ? 'e' : ''} le `}
              <b>
                {DateUtils.APIStrToLocalDateString(updatedAt)}
              </b>
              {` à `}
              <b>
                {DateUtils.APIStrToLocalTimeString(updatedAt, { hour: '2-digit', minute: '2-digit' })}
              </b>
              .
            </React.Fragment>
        }
      </React.Fragment>
    )
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <CloseButton handleClose={handleClose} />
      <DialogContent>
        <Form form={formikForm}>
          <FormBoldTitle
            smaller
            required
          >
            Titre
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Titre"
            name="title"
            size="small"
            multiline
          />
          <FormBoldTitle smaller>
            Description
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Description"
            name="description"
            size="small"
            value={formikForm.values.description || ''}
            multiline
            rows={3}
          />
          <DialogActionContainer>
            {
              reviewCard && (isAllowed({ userId: reviewCard.userId }) || isAllowed({ userId: reviewBoardUserId })) &&
                <CustomIconButton
                  Icon={Delete}
                  variant="contained"
                  color="error"
                  onClick={onDeleteCard}
                  disabled={isDeletingReviewCard}
                />
            }
            <FormButton
              variant="contained"
              type="submit"
              disabled={formikForm.isSubmitting}
            >
              {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Enregistrer'}
            </FormButton>
          </DialogActionContainer>
        </Form>
        {
          reviewCard &&
            <React.Fragment>
              <Info>
                <Avatar
                  size="small"
                  initials={reviewCard.user?.initials}
                  avatarColor={reviewCard.user?.avatarColor}
                />
                <div>
                  <b>
                    {reviewCard.user?.firstNameWithInitial}
                  </b>
                  {` à ajouté cette carte à `}
                  <b>
                    {reviewCard.boardCategory?.name}
                  </b>
                  {displayLastEdit(reviewCard.createdAt, reviewCard.updatedAt, true)}
                </div>
              </Info>
              <CommentsContainer>
                {
                  reviewCard.comments?.map((comment) => (
                    <ReviewsCardModalComment
                      key={comment.id}
                      comment={comment}
                      reviewCardId={reviewCard.id}
                      reviewBoardId={reviewBoardId}
                      displayLastEdit={displayLastEdit}
                      allowRefresh={allowRefresh}
                    />
                  ))
                }
              </CommentsContainer>
              <AddCommentContainer>
                {
                  addComment ?
                    <ReviewsCardModalCommentAddEdit
                      reviewBoardId={reviewBoardId}
                      reviewCardId={reviewCard.id}
                      handleClose={onAddCommentClose}
                    /> :
                    <Button
                      variant="outlined"
                      onClick={() => setAddComment(true)}
                    >
                      Ajouter un commentaire
                    </Button>
                }
              </AddCommentContainer>
            </React.Fragment>
        }
      </DialogContent>
    </Dialog>
  )
}

export default ReviewsCardModal
