/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useDeleteIncidentMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type { Incident } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

/* Component declaration ---------------------------------------------------- */
interface IncidentsDeleteModalProps {
  open: boolean;
  handleClose: () => void;
  incident: Incident;
}

const IncidentsDeleteModal: React.FC<IncidentsDeleteModalProps> = ({
  open,
  handleClose,
  incident,
}) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const [
    submitDeleteIncident,
  ] = useDeleteIncidentMutation()

  const onDeleteClick = () => {
    setIsLoading(true)
    submitDeleteIncident(incident.id)
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue.`)
        } else {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        {`Suppression de ${incident.id}`}
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {`Confirmez-vous la suppression de ${incident.id} ? L'historique et les pièces jointes associées seront aussi supprimés. Cette action est irréversible.`}
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          variant="outlined"
          onClick={handleClose}
        >
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          color="error"
          disabled={isLoading}
          onClick={onDeleteClick}
        >
          {isLoading ? <CircularProgress size={26} /> : 'Supprimer'}
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default IncidentsDeleteModal
