/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostBulkActivitiesMutation } from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { getRandomNegativeInt } from 'helpers/getRandomInt'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { toast } from 'react-toastify'
import ActivitiesModalItem from './ActivitiesModalItem'
import ActivitiesModalCard from './ActivitiesModalCard'
import ActivitiesDeleteModal from './ActivitiesDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikErrors,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Activity,
  ActivityStoreBulk,
  ActivityStoreBulkData,
  Client,
  Project,
  UserSettings,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const activityBulkSchema = Yup.object<Shape<ActivityStoreBulk>>({
  activities: Yup.array(Yup.object<Shape<ActivityStoreBulkData>>({
    comment: Yup.string().required('Le commentaire est obligatoire'),
    date: Yup.string().required('La date est obligaroire'),
    imputationId: Yup.string().nullable().required("L'imputation est obligatoire"),
    projectId: Yup.string().nullable().required('Le projet est obligatoire'),
    clientId: Yup.string().nullable(),
    nbHours: Yup.number().max(10, 'La durée ne peut pas dépasser 10.').required('La durée est obligatoire'),
  })).required(),
}).required()

type ActivityBulkRequest = FormikContextType<ActivityStoreBulk>

/* Styled components -------------------------------------------------------- */
const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

const ItemContainer = styled(Card)`
  margin: 10px;
  padding: 10px;
`

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0px 10px;
  color: ${(props) => props.theme.palette.error.main};
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesModalProps {
  open: boolean;
  handleClose: () => void;
  existingActivities: Activity[];
  days: string[];
  projectList: Project[];
  isFetchingProjectList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  settings?: UserSettings;
}

const ActivitiesModal: React.FC<ActivitiesModalProps> = ({
  open,
  handleClose,
  existingActivities,
  days,
  settings,
  ...props
}) => {
  const [ displayErrors, setDisplayErrors ] = useState<boolean>(false)
  const [ deleteModal, setDeleteModal ] = useState<ActivityStoreBulkData | null>()

  const [
    submitActivityBulk,
  ] = usePostBulkActivitiesMutation()

  const formikForm: ActivityBulkRequest = useForm<ActivityStoreBulk>(
    {
      initialValues: {
        activities: [],
      },
      validationSchema: activityBulkSchema,
    },
  )

  useEffect(() => {
    const data: ActivityStoreBulkData[] = []

    days.forEach((day) => {
      const activities = existingActivities.filter((ac) => DateUtils.APIStrToShortDate(ac.date) === DateUtils.APIStrToShortDate(day))
      if (activities.length > 0) {
        data.push(...activities)
      } else {
        data.push({ id: getRandomNegativeInt(), clientId: '', comment: '', date: day, imputationId: '', projectId: '', nbHours: DateUtils.getCalendarHourAmount(new Date(day).getDay(), settings) })
      }
    })

    formikForm.setFieldValue('activities', data)
  }, [ existingActivities, days ])

  const onSubmit = (): void => {
    setDisplayErrors(true)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitActivityBulk(formikForm.values)
      })
      .then((response) => {
        if (!response) return
        else if (isApiError(response)) {
          toast.error('Une erreur est survenue.')
          formikForm.setSubmitting(false)
        }
        else {
          formikForm.resetForm()
          handleClose()
        }
      })
      .catch(console.error)
  }

  const filterFormByDays = useMemo(() => {
    const group: {[day: string ]: ActivityStoreBulkData[]} = {}

    formikForm.values.activities.forEach((before) => {
      if (!group[DateUtils.APIStrToShortDate(before.date)]?.length) group[DateUtils.APIStrToShortDate(before.date)] = []
      group[DateUtils.APIStrToShortDate(before.date)].push(before)
    })

    const sortedKeys = Object.keys(group).sort().reduce((objEntries: {[day: string ]: ActivityStoreBulkData[]}, key) => {
      objEntries[key] = group[key]
      return objEntries
    }, {})

    return sortedKeys
  }, [ formikForm.values.activities, days ])

  const errorMessage = useMemo(() => {
    const msg: string[] = []
    if (!displayErrors || !formikForm.errors.activities?.length || formikForm.errors.activities?.length === 0) return msg

    const cleaned: number[] = [];
    (formikForm.errors.activities as FormikErrors<ActivityStoreBulkData>[]).forEach((e, index) => {if (e) cleaned.push(index)})

    const dates: string[] = [ ...new Map(cleaned.map((index) => new Date(formikForm.values.activities[index].date).toISOString()).map((v) => [ v, v ])).values() ].sort((a, b) => a.localeCompare(b))
    dates.forEach((date) => msg.push(`Le ${DateUtils.APIStrToLocalDateString(date, { month: '2-digit', day: '2-digit', year: 'numeric' })} est invalide.\n`))

    return msg
  }, [ formikForm.errors, displayErrors, formikForm.values ])

  const findFormIndex = (activity: ActivityStoreBulkData): number => {
    return formikForm.values.activities.findIndex((data) => DateUtils.APIStrToShortDate(data.date) === DateUtils.APIStrToShortDate(activity.date) && data.id === activity.id)
  }

  const nbOfHoursPerDay = (day: string) => {
    const sameDayActivities = formikForm.values.activities.filter((ac) => DateUtils.APIStrToShortDate(ac.date) === DateUtils.APIStrToShortDate(day))
    return sameDayActivities.reduce((a, b) => a + Number(b.nbHours), 0)
  }

  const onNewDayActivityClick = (day: string) => {
    const left = nbOfHoursPerDay(day)
    const selectedDay = DateUtils.getCalendarHourAmount(new Date(day).getDay(), settings)
    const nbHours = selectedDay - left > 0 ? selectedDay - left : selectedDay

    formikForm.setFieldValue('activities', [ ...formikForm.values.activities, { id: getRandomNegativeInt(), clientId: '', comment: '', date: day, imputationId: '', projectId: '', nbHours } ])
  }

  const onDeleteActivity = (id: number | false) => {
    if (id) {
      const newData = structuredClone(formikForm.values.activities)
      const index = newData.findIndex((ac) => ac.id === id)
      if (id < 0) {
        newData.splice(index, 1)
      } else {
        newData[index].delete = true
      }
      formikForm.setFieldValue('activities', newData)
    }
    setDeleteModal(null)
  }

  const openDeleteActivitiesModal = (activity: ActivityStoreBulkData) => {
    if (activity.id < 0) {
      onDeleteActivity(activity.id)
    } else {
      setDeleteModal(activity)
    }
  }

  const onDuplicateActivityClick = (activity: ActivityStoreBulkData) => {
    const newData = [ ...formikForm.values.activities, { ...activity, id: getRandomNegativeInt() } ]
    formikForm.setFieldValue('activities', newData)
  }

  const getImputationList = (projectId: string, imputationId: string, index: number) => {
    const imputationList = props.projectList.find((p) => p.id === projectId)?.imputations ?? []

    if (imputationId && !imputationList.find((i) => i.imputationId === imputationId)) {
      formikForm.setFieldValue(`activities[${index}].imputationId`, '')
    }

    return imputationList
  }

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogContent>
          {
            Object.entries(filterFormByDays).map(([ day, activities ]) => (
              <ActivitiesModalCard
                day={day}
                hours={nbOfHoursPerDay(day)}
                key={day}
                onNewDayActivityClick={onNewDayActivityClick}
              >
                {
                  activities.filter((a) => !a.delete).map((activity) => (
                    <ItemContainer key={activity.id}>
                      <ActivitiesModalItem
                        index={findFormIndex(activity)}
                        onDeleteClick={() => openDeleteActivitiesModal(activity)}
                        onDuplicateClick={() => onDuplicateActivityClick(activity)}
                        imputationList={getImputationList(activity.projectId, activity.imputationId, findFormIndex(activity))}
                        {...props}
                      />
                    </ItemContainer>
                  ))
                }
              </ActivitiesModalCard>
            ))
          }
        </DialogContent>
        <ErrorMessageContainer>
          {
            errorMessage.map((e, index) => (
              <div key={index}>
                {e}
              </div>
            ))
          }
        </ErrorMessageContainer>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            disabled={formikForm.isSubmitting}
            onClick={onSubmit}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
        {
          deleteModal &&
            <ActivitiesDeleteModal
              open
              activity={deleteModal}
              handleClose={(id: number | false) => onDeleteActivity(id)}
            />
        }
      </Form>
    </Dialog>
  )
}

export default ActivitiesModal
