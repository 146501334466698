/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'

export interface Review {
  open: boolean;
}

/* Redux slice -------------------------------------------------------------- */
const reviewSlice = createSlice(
  {
    name: 'review',
    initialState: {
      open: false,
    } as Review,
    reducers: {
      setReviewOpen: (state, { payload }: PayloadAction<boolean>) => {
        state.open = payload
      },
      toggleReviewOpen: (state) => {
        state.open = !state.open
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setReviewOpen,
  toggleReviewOpen,
} = reviewSlice.actions

export default reviewSlice.reducer

export const selectReview = (state: RootState): Review => {
  return state.review
}
