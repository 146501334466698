/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setAuthTeamViewerCode } from 'store/slices/authSlice'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import RouteTitle from 'router/RouteTitle'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'

/* Styled components -------------------------------------------------------- */
const Message = styled.div`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 10px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface TeamViewerLoginPageProps {}

const TeamViewerLoginPage: React.FC<TeamViewerLoginPageProps> = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const teamViewerCode = location.search.split('?code=')[1]
    if (isValidString(teamViewerCode)) {
      dispatch(setAuthTeamViewerCode({ teamViewerCode }))

      const bodyToEncode: {[x: string]: string} = {
        'grant_type': 'authorization_code',
        code: teamViewerCode,
        'redirect_uri': `${origin}/connexion-teamviewer`,
        'client_id': process.env.REACT_APP_TEAMVIEWER_CLIENT_ID || '',
        'client_secret': process.env.REACT_APP_TEAMVIEWER_CLIENT_SECRET || '',
      }

      const formBody: string[] = []

      for (const property in bodyToEncode) {
        const encodedKey = encodeURIComponent(property)
        const encodedValue = encodeURIComponent(bodyToEncode[property])
        formBody.push(`${encodedKey }=${ encodedValue}`)
      }

      fetch(`https://webapi.teamviewer.com/api/v1/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody.join('&'),
      })
        .then(async (value) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.teamviewer = await value.json() as JSON
          window.close()
        })
        .catch(console.error)
    }
  }, [ location ])

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Connexion TeamViewer" />
      <AuthContainer>
        <AuthWelcomeMessage>
          Connexion à TeamViewer
        </AuthWelcomeMessage>
        <Message>
          En attente de TeamViewer...
          <CircularProgress />
        </Message>
      </AuthContainer>
    </BaseLayout>
  )
}

export default TeamViewerLoginPage
