/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

/* Component imports -------------------------------------------------------- */
import Avatar from 'components/Avatar/Avatar'

/* Type imports ------------------------------------------------------------- */
import type { Winner } from './Podium'

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  overflow-y: hidden;
`

const AvatarContainer = styled(motion.div)`
  margin-bottom: 10px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 48px;
    height: 48px;
    margin-bottom: -12px;
    z-index: 3;
  }

  #idCrown path {
    fill: url(#gradientCrown);
  }
`

interface PlaceContainerProps {
  opacity: number;
  color?: string;
}

const PlaceContainer = styled(motion.div)<PlaceContainerProps>`
  background: ${(props) => props.color || props.theme.palette.primary.main};
  width: 4rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  justify-content: center;
  cursor: pointer;
  filter: opacity(${(props) => props.opacity});

  &:hover {
    background-color: ${(props) => props.theme.palette.info.main};
  }
`

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface PodiumStepProps {
  podium: Winner[];
  winner: Winner;
  index: number;
}

const PodiumStep: React.FC<PodiumStepProps> = ({ podium, winner, index }) => {
  const colors: string[] = [
    'linear-gradient(333deg, #ffac25 0%, #fecf33 13%, #fee033 53%,#ffac25 100%)',
    'linear-gradient(45deg, #ededed, #bdbdbd)',
    'linear-gradient(45deg, #e0982d, #80501a)',
  ]

  const date = new Date(winner.time)

  const getPodiumHeight = () => {
    return (document.getElementById('podium-container')?.clientHeight ?? 600) - 220
  }

  return (
    <Container>
      <AvatarContainer
        custom={index}
        initial="hidden"
        animate="visible"
        variants={
          {
            visible: () => ({
              opacity: 1,
              transition: {
                delay: 1 + (podium.length - winner.place + 2),
                duration: 0.75,
              },
            }),
            hidden: { opacity: 0 },
          }
        }
      >
        {
          winner.place === 1 &&
            <svg
              id="idCrown"
              viewBox="0 0 24 24"
            >
              <linearGradient
                id="gradientCrown"
                gradientTransform="rotate(120)"
              >
                <stop
                  offset="0%"
                  stopColor="#fecf33"
                />
                <stop
                  offset="100%"
                  stopColor="#ffac25"
                />
              </linearGradient>
              <path d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5M19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z" />
            </svg>
        }
        <Avatar
          initials={winner.initials}
          avatarColor={winner.avatarColor}
        />
      </AvatarContainer>
      <PlaceContainer
        custom={index}
        initial="hidden"
        animate="visible"
        opacity={0.2 + (podium.length - winner.place) / podium.length}
        color={winner.place === 1 ? colors[0] : winner.place === 2 ? colors[1] : winner.place === 3 ? colors[2] : undefined}
        variants={
          {
            visible: () => ({
              height: (getPodiumHeight() * ((podium.length - winner.place) / podium.length)) + 120,
              opacity: 2,
              transition: {
                delay: 1 + (podium.length - winner.place),
                duration: 2,
                ease: 'backInOut',
              },
            }),
            hidden: { opacity: 0, height: 0 },
          }
        }
      >
        <ResultContainer>
          <b>
            {`${winner.place}${winner.place === 1 ? 'er' : 'ème'}`}
          </b>
          <div>
            <b>
              {date.getHours()}
            </b>
            h
          </div>
          <div>
            <b>
              {date.getMinutes()}
            </b>
            min
          </div>
          <div>
            <b>
              {date.getSeconds()}
            </b>
            s
          </div>
          <b>
            {date.getMilliseconds()}
          </b>
        </ResultContainer>
      </PlaceContainer>
    </Container>
  )
}

export default PodiumStep
