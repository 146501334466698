/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { usePatchReviewBoardCategoryMutation } from 'store/api'
import { isAllowed } from 'helpers/isAllowed'
import { useAppDispatch } from 'store/hooks'
import { setReviewOpen } from 'store/slices/reviewSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  IconButton,
  TextField,
} from '@mui/material'
import {
  Add,
  Delete,
} from '@mui/icons-material'
import StrictModeDroppable from 'components/StrictModeDroppable/StrictModeDroppable'
import ReviewsCard from './ReviewsCard'
import ReviewsCardModal from './ReviewsCardModal'
import ReviewsCategoryDeleteModal from './ReviewsCategoryDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { ReviewBoardCategory } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const StyledColumn = styled(Card)`
  margin-top: 10px;
  padding: 5px 10px 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 200px;
  transition: height .7s ease-in-out;
  box-shadow: ${(props) => props.theme.colors.darkgrey}19 0px 10px 15px -6px, ${(props) => props.theme.colors.darkgrey}0C 0px 4px 6px -2px;
  border: none !important;
`

interface StyledListProps {
  maxheight: string;
}

const StyledList = styled(Card)<StyledListProps>`
  min-height: 150px;
  max-height: calc(100vh - ${(props) => props.maxheight});
  overflow-y: auto;
  transition: height .7s ease-in-out;
  border: none !important;
`

interface TitleTextFieldProps {
  editing: string;
}

const TitleTextField = styled(TextField)<TitleTextFieldProps>`
  .MuiInputBase-root {
    background-color: ${(props) => props.editing === 'true' ? props.theme.colors.main : 'transparent'};
    border: ${(props) => props.editing === 'true' ? `1px solid ${props.theme.palette.primary.main}` : 'none'};
  }

  fieldset {
    border: ${(props) => props.editing === 'true' ? `1px solid ${props.theme.palette.primary.main}` : 'none'};
  }

  input {
    text-overflow: ellipsis;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsCategoryProps {
  category: ReviewBoardCategory;
  userId: number;
}

const ReviewsCategory: React.FC<ReviewsCategoryProps> = ({
  category: { reviewBoardId, name, id, cards = []},
  userId,
}) => {
  const dispatch = useAppDispatch()
  const [ editTitle, setEditTitle ] = useState<boolean>(false)
  const [ newTitle, setNewTitle ] = useState<string>('')
  const [ openNewModal, setOpenNewModal ] = useState<boolean>(false)
  const [ openDeleteModal, setOpenDeleteModal ] = useState<boolean>(false)

  const [
    submitPatchBoardCategory,
  ] = usePatchReviewBoardCategoryMutation()

  const onOpenEditTitle = () => {
    setEditTitle(true)
    dispatch(setReviewOpen(true))
  }

  const onCloseEditTitle = () => {
    setEditTitle(false)
    dispatch(setReviewOpen(false))
  }

  const onEditTitle = async () => {
    if (!newTitle || !isAllowed({ userId })) return

    await submitPatchBoardCategory({ id, reviewBoardId, data: { name: newTitle }}).then(onCloseEditTitle)
  }

  const onClickEditTitle = () => {
    if (isAllowed({ userId })) {
      onOpenEditTitle()
      setNewTitle(name)
    }
  }

  const onOpenNewModal = () => {
    setOpenNewModal(true)
    dispatch(setReviewOpen(true))
  }

  const onCloseNewModal = () => {
    setOpenNewModal(false)
    dispatch(setReviewOpen(false))
  }

  const getStyledListMaxHeight = () => {
    return `${(document.getElementById(`category-column-${id}`)?.getBoundingClientRect().top ?? 200) + 100}px`
  }

  return (
    <StrictModeDroppable droppableId={id.toString()}>
      {
        (provided) => (
          <StyledColumn id={`category-column-${id}`}>
            <Title>
              {
                isAllowed({ userId }) &&
                  <IconButton
                    color="error"
                    onClick={() => setOpenDeleteModal(true)}
                  >
                    <Delete />
                  </IconButton>
              }
              <TitleTextField
                editing={String(editTitle)}
                value={editTitle ? newTitle : name || ''}
                onChange={(e) => isAllowed({ userId }) && setNewTitle(e.target.value)}
                onSubmit={onEditTitle}
                size="small"
                onBlur={onCloseEditTitle}
                onClick={onClickEditTitle}
                onKeyDown={(e) => e.key === 'Enter' ? onEditTitle() : e.key === 'Escape' ? onCloseEditTitle() : null}
              />
              <IconButton
                color="info"
                onClick={onOpenNewModal}
              >
                <Add />
              </IconButton>
            </Title>
            <StyledList
              {...provided.droppableProps}
              ref={provided.innerRef}
              maxheight={getStyledListMaxHeight()}
            >
              {
                cards.map((card, index) => (
                  <ReviewsCard
                    key={card.id}
                    card={card}
                    index={index}
                    reviewBoardUserId={userId}
                  />
                ))
              }
              {provided.placeholder}
            </StyledList>
            {
              openNewModal &&
                <ReviewsCardModal
                  handleClose={onCloseNewModal}
                  reviewBoardId={reviewBoardId}
                  reviewBoardCategoryId={id}
                  reviewBoardUserId={userId}
                />
            }
            {
              openDeleteModal &&
                <ReviewsCategoryDeleteModal
                  handleClose={() => setOpenDeleteModal(false)}
                  reviewBoardId={reviewBoardId}
                  id={id}
                  name={name}
                />
            }
          </StyledColumn>
        )
      }
    </StrictModeDroppable>
  )
}

export default ReviewsCategory
