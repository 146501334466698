/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostReviewRateMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ReviewRateStoreValidator } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const reviewRateBulkSchema = Yup.object<Shape<ReviewRateStoreValidator>>({
  rate: Yup.number().min(1),
}).required()

type ReviewRateRequest = FormikContextType<ReviewRateStoreValidator>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  display: flex;
  justify-content: center;

  label {
    font-size: 3rem;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsRateModalProps {
  reviewBoardId: number;
  rate: number;
  handleClose: () => void;
}

const ReviewsRateModal: React.FC<ReviewsRateModalProps> = ({
  handleClose,
  reviewBoardId,
  rate,
}) => {
  const [
    submitNewReviewRate,
  ] = usePostReviewRateMutation()

  const onSubmit = (values: ReviewRateStoreValidator, { setSubmitting, resetForm }: FormikHelpers<ReviewRateStoreValidator>) => {
    submitNewReviewRate({ reviewBoardId, data: { ...values }})
      .then((response) => {
        if (!isApiError(response)) {
          resetForm()
          handleClose()
        } else {
          toast.error('Une erreur est survenue.')
          setSubmitting(false)
        }
      }).catch(console.error)
  }

  const formikForm: ReviewRateRequest = useForm<ReviewRateStoreValidator>(
    {
      initialValues: {
        rate,
      },
      onSubmit: onSubmit,
      validationSchema: reviewRateBulkSchema,
    },
  )

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Noter ma semaine
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContentContainer>
          <Rating
            size="large"
            value={formikForm.values.rate}
            onChange={(e, value) => formikForm.setFieldValue('rate', value)}
          />
        </DialogContentContainer>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default ReviewsRateModal
