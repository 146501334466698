/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  Client,
  ProjectStatus,
  Software,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  align-items: flex-start;

  @media ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 0px;
    margin-top: -10px;
  }
`

const Container = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: calc(50% - 5px);
  }
`

const CommentContainer = styled.div`
  width: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface ProjectsModalItemProps {
  edit?: boolean;
  softwareList: Software[];
  isFetchingSoftwareList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  statusList: ProjectStatus[];
  isFetchingStatusList: boolean;
  projectCodeHelper?: string;
}

const ProjectsModalItem: React.FC<ProjectsModalItemProps> = ({
  softwareList = [],
  isFetchingSoftwareList,
  statusList = [],
  isFetchingStatusList,
  clientList = [],
  isFetchingClientList,
  edit = false,
  projectCodeHelper,
}) => {

  const getFullName = (value?: Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <ProjectContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle
            smaller
            required
          >
            Code Projet
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Code Projet"
            name="id"
            size="small"
            disabled={edit}
            helperText={projectCodeHelper}
          />
        </Container>
        <Container>
          <FormBoldTitle
            smaller
            required
          >
            Libellé Projet
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Libellé Projet"
            name="name"
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle smaller>
            Client
          </FormBoldTitle>
          <AutocompleteField
            name="clientId"
            options={clientList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
            disabled={isFetchingClientList}
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle
            required
            smaller
          >
            Logiciel
          </FormBoldTitle>
          <AutocompleteField
            name="softwareId"
            options={softwareList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => softwareList.find((p) => p.id === option)?.name || ''}
            disabled={isFetchingSoftwareList}
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle
            required
            smaller
          >
            Statut
          </FormBoldTitle>
          <AutocompleteField
            name="statusId"
            options={statusList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => statusList.find((p) => p.id === option)?.name || ''}
            disabled={isFetchingStatusList}
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        <CommentContainer>
          <FormBoldTitle
            smaller
            required
          >
            Description
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Description"
            name="description"
            size="small"
            rows={3}
            multiline
          />
        </CommentContainer>
      </RowContainer>
    </ProjectContainer>
  )
}

export default ProjectsModalItem
