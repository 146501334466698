/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 3 - 10px);

  @media ${(props) => props.theme.media.mobile.main} {
    width: calc(100% / 2 - 10px);
    margin-top: -15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface UsersFiltersProps {
  disabled: boolean;
  setDisabled: (value: boolean) => void;
}

const UsersFilters: React.FC<UsersFiltersProps> = ({
  disabled,
  setDisabled,
}) => {
  const options: SegmentedButtonOption<boolean>[] = [ { value: false, label: 'Actif' }, { value: true, label: 'Inactif' } ]

  return (
    <SelectFilterContainer>
      <Container>
        <FormBoldTitle>
          Recherche
        </FormBoldTitle>
        <Field
          component={TextField}
          placeholder="Rechercher..."
          name="search"
          size="small"
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Statut
        </FormBoldTitle>
        <SegmentedButtons
          options={options}
          selectedOption={disabled}
          setSelectedOption={setDisabled}
          smaller
        />
      </Container>
    </SelectFilterContainer>
  )
}

export default UsersFilters
