/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useLocation } from 'react-router-dom'
import fuzzysort from 'fuzzysort'
import {
  useAppDispatch,
  useAuthInfo,
} from 'store/hooks'
import { setAuthTeamViewerTokens } from 'store/slices/authSlice'
import { isApiError } from 'helpers/fetchHelpers'

/* Components imports ------------------------------------------------------- */
import {
  Badge,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Popper,
  Switch,
  TextField,
} from '@mui/material'
import {
  Cancel,
  CheckCircle,
} from '@mui/icons-material'
import CustomIconButtonContainer from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'
import { ReactComponent as TeamviewerLogo } from 'assets/TeamViewerLogo.svg'

/* Type declarations -------------------------------------------------------- */
interface Device {
  'remotecontrol_id': string;
  'device_id': string;
  'alias': string;
  'online_state': string;
  'supported_features': string;
}

interface Filters {
  search: string;
  onlyConnected: boolean;
  page: boolean;
}

interface TeamViewerWindow extends Window {
  teamviewer?: {
    'access_token': string;
    'refresh_token': string;
  };
}

/* Styled components -------------------------------------------------------- */
interface TeamViewerButtonContainerProps {
  connected?: string;
}

const TeamViewerButtonContainer = styled(CustomIconButtonContainer)<TeamViewerButtonContainerProps>`
  padding: 0px;
  filter: grayscale(${(props) => props.connected ? 0 : 1});
`

const PaperContainer = styled(Paper)`
  background-color: ${(props) => props.theme.colors.teamviewer};
`

const ListHeader = styled.div`
  padding: 20px 10px 10px;

  .MuiFormControlLabel-root {
    padding: 10px 10px 0px;
    font-size: .9rem;
  }
`

const List = styled.div`
  max-height: 60vh;
  overflow: auto;
`

const NameContainer = styled.div`
  margin-left: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface TeamViewerButtonProps {}

const TeamViewerButton: React.FC<TeamViewerButtonProps> = () => {
  const { teamViewerAccessToken = '', teamViewerRefreshToken = '' } = useAuthInfo()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [ connexionWindow, setConnexionWindow ] = useState<TeamViewerWindow | null>(null)
  const [ openMenu, setOpenMenu ] = useState<boolean>(false)
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null)
  const [ devices, setDevices ] = useState<Device[]>([])
  const [ filteredDevices, setFilteredDevices ] = useState<Device[]>([])
  const [ filters, setFilters ] = useState<Filters>({ onlyConnected: false, page: false, search: '' })

  const isSpecialPage = useMemo(() => (/clients\/.+|incidents\/.+/).test(location.pathname), [ location ])

  const refreshToken = () => {
    if (!teamViewerRefreshToken) {
      dispatch(setAuthTeamViewerTokens({ teamViewerAccessToken: undefined, teamViewerRefreshToken: undefined }))
      return
    }

    const bodyToEncode: {[x: string]: string} = {
      'grant_type': 'refresh_token',
      'refresh_token': teamViewerRefreshToken,
      'client_id': process.env.REACT_APP_TEAMVIEWER_CLIENT_ID || '',
      'client_secret': process.env.REACT_APP_TEAMVIEWER_CLIENT_SECRET || '',
    }

    const formBody: string[] = []

    for (const property in bodyToEncode) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(bodyToEncode[property])
      formBody.push(`${encodedKey }=${ encodedValue}`)
    }

    fetch(`https://webapi.teamviewer.com/api/v1/oauth2/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody.join('&'),
    }).then(async (response) => {
      const res = await response.json() as {'access_token': string; 'refresh_token': string}

      if (isApiError(res)) {
        console.error(res)
        dispatch(setAuthTeamViewerTokens({ teamViewerAccessToken: undefined, teamViewerRefreshToken: undefined }))
      } else {
        dispatch(setAuthTeamViewerTokens({ teamViewerAccessToken: res.access_token, teamViewerRefreshToken: res.refresh_token }))
      }
    }).catch((error) => {
      console.error(error)
      dispatch(setAuthTeamViewerTokens({ teamViewerAccessToken: undefined, teamViewerRefreshToken: undefined }))
    })
  }

  useEffect(() => {
    if (teamViewerAccessToken) {
      fetch(`https://webapi.teamviewer.com/api/v1/devices`, { headers: { 'Authorization': `Bearer ${teamViewerAccessToken}` }})
        .then(async (data) => {
          const res = await data.json() as {devices: Device[]}

          if (isApiError(res)) {
            console.error(res)
            refreshToken()
          } else if (res.devices) {
            setDevices(res.devices)
            setFilteredDevices(res.devices)
          }
        })
        .catch((error) => {
          console.error(error)
          refreshToken()
        })
    }
  }, [ teamViewerAccessToken ])

  useEffect(() => {
    if (connexionWindow) {
      const checkClosed = setInterval(() => {
        if (connexionWindow?.closed) {
          clearInterval(checkClosed)

          try {
            if (connexionWindow?.teamviewer) {
              const teamviewer = connexionWindow.teamviewer
              dispatch(setAuthTeamViewerTokens({ teamViewerAccessToken: teamviewer.access_token, teamViewerRefreshToken: teamviewer.refresh_token }))
              window.location.reload()
            }
          } catch(error) {
            console.error(error)
            setConnexionWindow(null)
          }
        }
      }, 1000)
    }
  }, [ connexionWindow ])

  useEffect(() => {
    const filterSearch = (options: Device[], inputValue: string) => {
      const opt = {
        limit: 100, // don't return more results than you need!
        threshold: 0,
        all: true,
        keys: [ 'alias' ],
      }
      const fuzzyResults = fuzzysort.go(inputValue, options, opt)
      const results = fuzzyResults.map(({ obj }) => obj)

      return results
    }

    let filtered = structuredClone(devices)

    if (filters.onlyConnected) {
      filtered = filtered.filter((device) => device.online_state === 'Online')
    }

    if (filters.page && isSpecialPage) {
      const clientIdElement = document.getElementsByName('clientId')[1] as HTMLInputElement
      const clientNameElement = document.getElementsByName('name')[0] as HTMLInputElement
      const contactElement = document.getElementsByName('contact')[0] as HTMLInputElement
      // const textElement = document.getElementsByName('comment')[0] as HTMLInputElement
      const pageFilters: Device[] = []

      if (clientIdElement?.value) {
        const filter = filterSearch(filtered, clientIdElement.value)

        if (filter.length > 0) {
          pageFilters.push(...filter)
        }
      }
      if (clientNameElement?.value) {
        const filter = filterSearch(filtered, clientNameElement.value)

        if (filter.length > 0) {
          pageFilters.push(...filter)
        }
      }
      if (contactElement?.value) {
        const filter = filterSearch(filtered, contactElement.value)

        if (filter.length > 0) {
          pageFilters.push(...filter)
        }
      }
      filtered = pageFilters
    }

    filtered = filterSearch(filtered, filters.search)

    setFilteredDevices(filtered)
  }, [ filters, isSpecialPage ])

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (teamViewerAccessToken) {
      setAnchorEl(event.currentTarget)
      setOpenMenu(!openMenu)
    } else {
      setConnexionWindow(window.open(`https://login.teamviewer.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TEAMVIEWER_CLIENT_ID}&redirect_uri=${origin}/connexion-teamviewer&display=popup`,
        'sub',
        'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800',
      ))
    }
  }

  const onDeviceClick = (device: Device) => {
    window.open(`https://start.teamviewer.com/${device.remotecontrol_id.replace('r', '')}`, '_blank')
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
      <Badge
        badgeContent={devices.length}
        invisible={!teamViewerAccessToken}
        color="secondary"
        anchorOrigin={
          {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
      >
        <TeamViewerButtonContainer
          onClick={onClick}
          connected={teamViewerAccessToken}
        >
          <TeamviewerLogo />
        </TeamViewerButtonContainer>
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
        >
          {
            ({ TransitionProps }) => (
              <Fade
                {...TransitionProps}
                timeout={350}
              >
                <PaperContainer>
                  <ListHeader>
                    <TextField
                      placeholder="Rechercher dans la liste"
                      size="small"
                      value={filters.search}
                      onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={filters.onlyConnected}
                            onChange={() => setFilters({ ...filters, onlyConnected: !filters.onlyConnected })}
                          />
                        }
                        label="Afficher seulement les connectés"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={!isSpecialPage ? false : filters.page}
                            onChange={() => setFilters({ ...filters, page: !filters.page })}
                            disabled={!isSpecialPage}
                          />
                        }
                        label="Recherche intelligente dans la page"
                      />
                    </FormGroup>
                  </ListHeader>
                  <List>
                    {
                      filteredDevices.map((device, index) => (
                        <MenuItem
                          key={`${device.device_id}-${index}`}
                          onClick={() => onDeviceClick(device)}
                        >
                          {
                            device.online_state === 'Online' ?
                              <CheckCircle color="secondary" /> :
                              <Cancel color="error" />
                          }
                          <NameContainer>
                            {device.alias}
                          </NameContainer>
                        </MenuItem>
                      ))
                    }
                  </List>
                </PaperContainer>
              </Fade>
            )
          }
        </Popper>
      </Badge>
    </ClickAwayListener>
  )
}

export default TeamViewerButton
