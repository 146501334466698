/* Framework imports -------------------------------------------------------- */
import { useMemo } from 'react'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import {
  persistor,
  store,
} from 'store/store'
import { getAuthFromLocalStorage } from 'helpers/localStorageHelpers'
import {
  setAuth,
  selectAuthInfo,
  resetAuth,
} from './slices/authSlice'

/* Type imports ------------------------------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from 'store/store'
import type { Auth } from './slices/authSlice'

/* Generic Redux store hooks ------------------------------------------------ */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector

/* Specific Redux store hooks ----------------------------------------------- */
export const useAuthInfo = (): Auth => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Auth = useAppSelector(
    selectAuthInfo,
    (pLeft: Auth, pRight: Auth): boolean => pLeft.token === pRight.token,
  )

  return useMemo<Auth>((): Auth => {
    if (!lLoginInfo.token) {
      const lLocalStorageAuthInfo = getAuthFromLocalStorage()

      if (lLocalStorageAuthInfo) {
        dispatch(setAuth(lLocalStorageAuthInfo))
        return lLocalStorageAuthInfo
      }

      return {
        id: 0,
        isAdmin: false,
        teamViewerAccessToken: undefined,
        teamViewerCode: undefined,
        teamViewerRefreshToken: undefined,
        email: '',
        updatedAt: '',
        createdAt: '',
        avatarColor: '',
        firstNameWithInitial: '',
        fullName: '',
        initials: '',
      }
    }

    return lLoginInfo
  }, [ lLoginInfo, dispatch ])
}

export const onLogoutReset = () => {
  persistor.pause()
  persistor.flush().then(() => {return persistor.purge()}).catch(console.error)
  store.dispatch(resetAuth())
}
