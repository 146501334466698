/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
// Main Pages
import LoginPage from 'pages/AuthPages/LoginPage/LoginPage'
import FirstLoginPage from 'pages/AuthPages/FirstLoginPage/FirstLoginPage'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import ActivitiesPage from 'pages/ActivitiesPage/ActivitiesPage'
import ProjectsPage from 'pages/ProjectsPage/ProjectsPage'
import ProfilePage from 'pages/ProfilePage/ProfilePage'
import IncidentsPage from 'pages/IncidentsPage/IncidentsPage'
import IncidentPage from 'pages/IncidentPage/IncidentPage'
import NewIncidentPage from 'pages/NewIncidentPage/NewIncidentPage'
import NewClientPage from 'pages/NewClientPage/NewClientPage'
import ClientsPage from 'pages/ClientsPage/ClientsPage'
import ClientPage from 'pages/ClientPage/ClientPage'
import EnvironmentUrlsPage from 'pages/EnvironmentUrlsPage/EnvironmentUrlsPage'
import TeamViewerLoginPage from 'pages/AuthPages/TeamViewerLoginPage/TeamViewerLoginPage'
import ReviewsPage from 'pages/ReviewsPage/ReviewsPage'
import UsersPage from 'pages/UsersPage/UsersPage'

/* Component declaration ---------------------------------------------------- */
interface AuthRouterProps {}

const Router: React.FC<AuthRouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log('[DEBUG] authInfo :', authInfo)

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo?.token ?
            <MainLayout /> :
            <Navigate
              to="/connexion"
              replace
            />
        }
      >
        <Route
          path="cra"
          element={<ActivitiesPage />}
        />
        <Route
          path="projets"
          element={<ProjectsPage />}
        />
        <Route
          path="incident/nouveau"
          element={<NewIncidentPage />}
        />
        <Route path="incidents">
          <Route
            index
            element={<IncidentsPage />}
          />
          <Route
            path=":incidentId"
            element={<IncidentPage />}
          />
        </Route>
        <Route
          path="client/nouveau"
          element={<NewClientPage />}
        />
        <Route path="clients">
          <Route
            index
            element={<ClientsPage />}
          />
          <Route
            path=":clientId"
            element={<ClientPage />}
          />
        </Route>
        <Route
          path="environnements"
          element={<EnvironmentUrlsPage />}
        />
        <Route
          path="collaborateurs"
          element={<UsersPage />}
        />
        <Route
          path="reviews"
          element={<ReviewsPage />}
        />
        <Route
          path="mon-compte"
          element={<ProfilePage />}
        />
        <Route
          index
          element={
            <Navigate
              to="/cra"
              replace
            />
          }
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route
        element={
          !authInfo?.token ?
            <AuthLayout /> :
            <Navigate
              to="/"
              replace
            />
        }
      >
        <Route
          path="/connexion"
          element={<LoginPage />}
        />
        <Route
          path="/premiere-connexion"
          element={<FirstLoginPage />}
        />
      </Route>
      <Route
        path="/connexion-teamviewer"
        element={<TeamViewerLoginPage />}
      />
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
