/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  initialValues,
  type UserSchema,
} from 'pages/UsersPage/UsersPage'

/* Redux slice -------------------------------------------------------------- */
const usersFormSlice = createSlice(
  {
    name: 'usersForm',
    initialState: initialValues,
    reducers: {
      setUsersForm: (state, { payload }: PayloadAction<UserSchema>) => {
        return payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setUsersForm } = usersFormSlice.actions

export default usersFormSlice.reducer

export const getUsersForm = (state: RootState): UserSchema => {
  return state.usersForm
}
