/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  usePostIncidentActionMutation,
  useGetIncidentActionListQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
  grid-template-columns: repeat(2, 1fr);

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const FillCheckableButton = styled(CheckableButton)`
  height: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface IncidentActionModalProps {
  open: boolean;
  handleClose: () => void;
  incidentId: number;
}

const IncidentActionModal: React.FC<IncidentActionModalProps> = ({
  open,
  handleClose,
  incidentId,
}) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ comment, setComment ] = useState<string>('')
  const [ actionId, setActionId ] = useState<string>('99')

  const {
    currentData: actionList = [],
    isFetching: isFetchingActionList,
  } = useGetIncidentActionListQuery()
  const [
    submitNewIncidentAction,
  ] = usePostIncidentActionMutation()

  const onClick = () => {
    setIsLoading(true)
    submitNewIncidentAction({ incidentId, data: { actionId, comment }})
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors de l'ajout d'une nouvelle action.`)
        } else {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleContainer>
        Nouvelle Action
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {
          isFetchingActionList ?
            <CircularProgress /> :
            <>
              <FormBoldTitle required>
                Action
              </FormBoldTitle>
              <GridContainer>
                {
                  actionList.map((value, index) => (
                    <FillCheckableButton
                      key={`${value.id}-${index}`}
                      checked={actionId === value.id}
                      onChange={() => setActionId(value.id)}
                      label={value.name}
                    />
                  ))
                }
              </GridContainer>
              <FormBoldTitle>
                Commentaire
              </FormBoldTitle>
              <TextField
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Commentaire"
                multiline
                rows={3}
              />
            </>
        }
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          variant="outlined"
          onClick={handleClose}
        >
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          disabled={isLoading}
          onClick={onClick}
        >
          {isLoading ? <CircularProgress size={26} /> : 'Valider'}
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default IncidentActionModal
