/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetClientListQuery,
  useGetEnvironmentUrlPaginatedQuery,
  useGetEnvironmentTypeListQuery,
  useGetProjectListQuery,
} from 'store/api'
import { exportToXLSX } from 'helpers/tableUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getEnvironmentUrlsForm,
  setEnvironmentUrlsForm,
} from 'store/slices/environmentUrlsFormSlice'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Collapse,
  Pagination,
} from '@mui/material'
import {
  DeleteOutlined,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Table from 'components/Table/Table'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import PasswordInput from 'components/AuthComponents/PasswordInput'
import EnvironmentUrlsFilters from './EnvironmentUrlsComponents/EnvironmentUrlsFilters'
import EnvironmentUrlsTableMobileCard from './EnvironmentUrlsComponents/EnvironmentUrlsTableMobileCard'
import EnvironmentUrlsModal from './EnvironmentUrlsComponents/EnvironmentUrlsModal'
import EnvironmentUrlsDeleteModal from './EnvironmentUrlsComponents/EnvironmentUrlsDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'
import type {
  EnvironmentUrl,
  IndexPaginatedEnvironmentUrlListParams,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const environmentsSchema = Yup.object({
  typeIds: Yup.array(Yup.number().required()).nullable(),
  clientIds: Yup.array(Yup.string().required()).nullable(),
  projectIds: Yup.array(Yup.string().required()).nullable(),
  orderBy: Yup.mixed<DataName>().required(),
  order: Yup.mixed<Order>().required(),
  page: Yup.number().required(),
  limit: Yup.number().required(),
}).required()

export type EnvironmentSchema = Yup.InferType<typeof environmentsSchema>

type EnvironmentForm = FormikContextType<EnvironmentSchema>

/* Internal Variables ------------------------------------------------------- */
export const initialValues: EnvironmentSchema = {
  typeIds: [],
  projectIds: [],
  clientIds: [],
  order: 'asc',
  orderBy: 'typeId',
  page: 1,
  limit: 25,
}

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const DeleteButton = styled(Button)`
  height: 35px;
  min-height: 35px;
  width: 40px;
  min-width: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface EnvironmentUrlsPageProps {}

const EnvironmentUrlsPage: React.FC<EnvironmentUrlsPageProps> = () => {
  const environmentsForm = useAppSelector(getEnvironmentUrlsForm)
  const dispatch = useAppDispatch()
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ openNewModal, setOpenNewModal ] = useState<boolean>(false)
  const [ editModal, setEditModal ] = useState<EnvironmentUrl | undefined>()
  const [ deleteModal, setDeleteModal ] = useState<EnvironmentUrl | null>()

  const {
    currentData: projectList = [],
    isFetching: isFetchingProjectList,
  } = useGetProjectListQuery()
  const {
    currentData: environmentTypeList = [],
    isFetching: isFetchingEnvironmentTypeList,
  } = useGetEnvironmentTypeListQuery()
  const {
    currentData: clientList = [],
    isFetching: isFetchingClientList,
  } = useGetClientListQuery()

  const formikForm: EnvironmentForm = useForm<EnvironmentSchema>(
    {
      initialValues: environmentsForm ?? initialValues,
      validationSchema: environmentsSchema,
    },
  )

  useEffect(() => {
    dispatch(setEnvironmentUrlsForm(formikForm.values))
  }, [ formikForm.values ])

  const {
    currentData: environmentPagination,
    isFetching: isFetchingEnvironmentPagination,
  } = useGetEnvironmentUrlPaginatedQuery(formikForm.values as unknown as IndexPaginatedEnvironmentUrlListParams)

  useEffect(() => {
    if (environmentPagination?.meta?.total !== undefined && environmentPagination?.meta?.total !== totalRows) {
      setTotalRows(environmentPagination?.meta?.total)
    }
  }, [ environmentPagination, isFetchingEnvironmentPagination ])

  const setOrder = (order: Order) => formikForm.setFieldValue('order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('orderBy', orderBy)
  const setLimit = (limit: number) => formikForm.setFieldValue('limit', limit)
  const setPage = (startIndex: number) => formikForm.setFieldValue('page', startIndex)

  const cols: ColumnHeader[] = [
    {
      id: 'url',
      label: 'Url',
    },
    {
      id: 'client.name',
      label: 'Client',
      sortId: 'clientId',
    },
    {
      id: 'project.name',
      label: 'Projet',
      sortId: 'projectId',
    },
    {
      id: 'type.name',
      label: "Type d'env",
      sortId: 'typeId',
    },
    {
      id: 'password',
      label: 'Mot de passe',
      render: (password: string) => (
        <PasswordInput
          value={password}
          disabled
          size="small"
          variant="standard"
        />
      ),
    },
    {
      id: 'euroBase',
      label: 'Base Euro',
    },
    {
      id: 'id',
      label: 'Actions',
      removeFromExport: true,
      render: (id: number, row) => (
        <DeleteButton
          variant="contained"
          color="error"
          onClick={(e) => {e.stopPropagation(); setDeleteModal(row as EnvironmentUrl)}}
        >
          <DeleteOutlined />
        </DeleteButton>
      ),
    },
  ]

  const exportDataToXLSX = () => {
    environmentPagination?.data && exportToXLSX(cols.filter((col) => !col.removeFromExport), environmentPagination.data, 'Environnements')
  }

  const onEditEnvironment = (env: EnvironmentUrl) => {
    setEditModal(env)
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Environnements
        <ButtonsContainer>
          <LongButton
            variant="outlined"
            onClick={exportDataToXLSX}
          >
            Exporter
          </LongButton>
          <LongButton
            variant="contained"
            onClick={() => setOpenNewModal(true)}
          >
            Nouvel environnement
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <FilterDesktopContainer>
        <EnvironmentUrlsFilters
          projectList={projectList}
          isFetchingProjectList={isFetchingProjectList}
          clientList={clientList}
          isFetchingClientList={isFetchingClientList}
          typeList={environmentTypeList}
          isFetchingTypeList={isFetchingEnvironmentTypeList}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <EnvironmentUrlsFilters
            projectList={projectList}
            isFetchingProjectList={isFetchingProjectList}
            clientList={clientList}
            isFetchingClientList={isFetchingClientList}
            typeList={environmentTypeList}
            isFetchingTypeList={isFetchingEnvironmentTypeList}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      <LoadingOverlay isLoading={isFetchingEnvironmentPagination}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={environmentPagination?.data ?? []}
              setRows={() => null}
              colHeaders={cols}
              defaultOrder={formikForm.initialValues}
              onRowClick={(row) => onEditEnvironment((row as EnvironmentUrl))}
              limit={formikForm.initialValues.limit}
              sorting={{ setOrder, setOrderBy }}
              pagination={{ totalRows, setLimit, setPage }}
              resultsPerPage={[ 10, 25, 50, 100, totalRows ?? 200 ]}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          <Pagination
            count={environmentPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
          {
            environmentPagination?.data?.map((environment) => (
              <EnvironmentUrlsTableMobileCard
                key={environment.id}
                environmentUrl={environment}
                onDelete={() => setDeleteModal(environment)}
                onEdit={() => onEditEnvironment(environment)}
              />
            ))
          }
          <Pagination
            count={environmentPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
        </MobileCardContainer>
      </LoadingOverlay>
      {
        (openNewModal || editModal) &&
          <EnvironmentUrlsModal
            open
            environmentUrl={editModal}
            clientList={clientList}
            isFetchingClientList={isFetchingClientList}
            projectList={projectList}
            isFetchingProjectList={isFetchingProjectList}
            typeList={environmentTypeList}
            isFetchingTypeList={isFetchingEnvironmentTypeList}
            handleClose={() => {setOpenNewModal(false); setEditModal(undefined)}}
          />
      }
      {
        deleteModal &&
          <EnvironmentUrlsDeleteModal
            open
            environmentUrl={deleteModal}
            handleClose={() => setDeleteModal(null)}
          />
      }
    </Form>
  )
}

export default EnvironmentUrlsPage
