/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostUserInfoMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import UsersModalItem from './UsersModalItem'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  UserInfo,
  UserInfoStoreValidator,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface UserStore extends UserInfoStoreValidator {
}

const userBulkSchema = Yup.object<Shape<UserStore>>({
  id: Yup.string().required("L'id est obligatoire"),
  email: Yup.string().email("L'email est invalide").required("L'email est obligatoire"),
  lastName: Yup.string().required('Le nom de famille est obligatoire'),
  firstName: Yup.string().required('Le prénom est obligatoire'),
}).required()

type UserRequest = FormikContextType<UserStore>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface UsersNewModalProps {
  open: boolean;
  handleClose: () => void;
  userInfoList: UserInfo[];
}

const UsersNewModal: React.FC<UsersNewModalProps> = ({
  open,
  handleClose,
  userInfoList = [],
  ...props
}) => {

  const [
    submitNewUser,
  ] = usePostUserInfoMutation()

  const onSubmit = (values: UserStore, { setSubmitting, resetForm }: FormikHelpers<UserStore>) => {
    submitNewUser(values)
      .then((response) => {
        if (!isApiError(response)) {
          resetForm()
          handleClose()
        } else {
          toast.error('Une erreur est survenue lors de la création du collaborateur.')
          setSubmitting(false)
        }
      }).catch(console.error)
  }

  const formikForm: UserRequest = useForm<UserStore>(
    {
      initialValues: {
        id: '',
        email: '',
        lastName: '',
        firstName: '',
      },
      onSubmit: onSubmit,
      validationSchema: userBulkSchema,
    },
  )

  const userCodeHelper = useMemo(() => {
    const { id } = formikForm.values
    if (id.length > 2) {
      const codes = userInfoList.filter((p) => p.id.toLowerCase().startsWith(id.toLowerCase())).map((p) => p.id).join(', ')
      return codes ? `Codes existants : ${codes}` : ''
    }
  }, [ formikForm.values.id ])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Nouveau collaborateur
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <UsersModalItem
            {...props}
            userCodeHelper={userCodeHelper}
            disabled={false}
            setDisabled={null}
          />
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default UsersNewModal
