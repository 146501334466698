/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  Client,
  Software,
  ProjectStatus,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 4 - 10px);

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
    margin-top: -15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ProjectsFiltersProps {
  softwareList: Software[];
  isFetchingSoftwareList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  statusList: ProjectStatus[];
  isFetchingStatusList: boolean;
}

const ProjectsFilters: React.FC<ProjectsFiltersProps> = ({
  softwareList,
  isFetchingSoftwareList,
  statusList,
  isFetchingStatusList,
  clientList,
  isFetchingClientList,
}) => {

  const getFullName = (value?: Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <SelectFilterContainer>
      <Container>
        <FormBoldTitle>
          Logiciel
        </FormBoldTitle>
        <AutocompleteField
          name="softwareIds"
          options={softwareList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => softwareList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingSoftwareList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Client
        </FormBoldTitle>
        <AutocompleteField
          name="clientIds"
          options={clientList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
          disabled={isFetchingClientList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Statut
        </FormBoldTitle>
        <AutocompleteField
          name="statusIds"
          options={statusList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => statusList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingStatusList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Project
        </FormBoldTitle>
        <Field
          component={TextField}
          placeholder="Rechercher un projet"
          name="projectId"
          size="small"
        />
      </Container>
    </SelectFilterContainer>
  )
}

export default ProjectsFilters
