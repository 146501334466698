/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetReviewBoardEmailQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
`

const DialogContentContainer = styled.div`
  margin: 0px 10px;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsEmailModalProps {
  reviewBoardId: number;
  handleClose: () => void;
}

const ReviewsEmailModal: React.FC<ReviewsEmailModalProps> = ({
  handleClose,
  reviewBoardId,
}) => {
  const [ message, setMessage ] = useState<string>('')

  const {
    currentData: email,
    isFetching: isFetchingEmail,
  } = useGetReviewBoardEmailQuery(reviewBoardId, { refetchOnMountOrArgChange: 1 })

  useEffect(() => {
    if (email && email.message) {
      setMessage(email.message)
    }
  }, [ isFetchingEmail ])

  const onCopyToClipBoard = async () => {
    if (typeof ClipboardItem !== 'undefined') {
      const html = new Blob([ message ], { type: 'text/html' })
      const text = new Blob([ message ], { type: 'text/plain' })
      const data = new ClipboardItem({ 'text/html': html, 'text/plain': text })
      await navigator.clipboard.write([ data ])
    }
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitleContainer>
        Compte-rendu
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        <RichTextEditor
          isLoading={isFetchingEmail}
          value={message}
          onValueChange={setMessage}
        />
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          onClick={onCopyToClipBoard}
          variant="contained"
        >
          Copier dans le presse-papier
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ReviewsEmailModal
