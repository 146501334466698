/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type {
  Client,
  EnvironmentType,
  Project,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  align-items: flex-start;

  @media ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 0px;
    margin-top: -10px;
  }
`

const Container = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: calc(50% - 5px);
  }
`

/* Component declaration ---------------------------------------------------- */
interface EnvironmentUrlsModalItemProps {
  projectList: Project[];
  isFetchingProjectList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  typeList: EnvironmentType[];
  isFetchingTypeList: boolean;
}

const EnvironmentUrlsModalItem: React.FC<EnvironmentUrlsModalItemProps> = ({
  projectList = [],
  isFetchingProjectList,
  typeList = [],
  isFetchingTypeList,
  clientList = [],
  isFetchingClientList,
}) => {

  const getFullName = (value?: Client | Project) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <ProjectContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle
            smaller
            required
          >
            Url
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Url"
            name="url"
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle smaller>
            Client
          </FormBoldTitle>
          <AutocompleteField
            name="clientId"
            options={clientList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
            disabled={isFetchingClientList}
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle smaller>
            Project
          </FormBoldTitle>
          <AutocompleteField
            name="projectId"
            options={projectList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => getFullName(projectList.find((p) => p.id === option))}
            disabled={isFetchingProjectList}
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle smaller>
            Type d'env
          </FormBoldTitle>
          <AutocompleteField
            name="typeId"
            options={typeList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => typeList.find((p) => p.id === option)?.name || ''}
            disabled={isFetchingTypeList}
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle smaller>
            Mot de passe
          </FormBoldTitle>
          <FormikPasswordInput
            name="password"
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle smaller>
            Base Euro
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Base Euro"
            name="euroBase"
            size="small"
          />
        </Container>
      </RowContainer>
    </ProjectContainer>
  )
}

export default EnvironmentUrlsModalItem
