/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type {
  Activity,
  ActivityStoreBulkData,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesDeleteModalProps {
  open: boolean;
  handleClose: (deleteActivity: number | false) => void;
  activity: Activity | ActivityStoreBulkData;
}

const ActivitiesDeleteModal: React.FC<ActivitiesDeleteModalProps> = ({
  open,
  handleClose,
  activity,
}) => {

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        {`Suppression de ${activity.comment}`}
        <CloseButton handleClose={() => handleClose(false)} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {`Confirmez-vous la suppression de ${activity.comment} ? Cette action est irréversible.`}
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          variant="outlined"
          onClick={() => handleClose(false)}
        >
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          color="error"
          onClick={() => handleClose(activity.id)}
        >
          Supprimer
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ActivitiesDeleteModal
