/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  usePostReviewActivityReportMutation,
  usePatchReviewActivityReportMutation,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

/* Type imports ------------------------------------------------------------- */
import type { ReviewActivityReport } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`

const FormButton = styled(Button)`
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 100%;
    width: 100%;
  }
`

const DialogContentContainer = styled.div`
  margin: 0px 10px;
`

const TipsContainer = styled.div`
  margin: 0px 20px 10px;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsReportEditModalProps {
  reviewBoardId: number;
  report?: ReviewActivityReport;
  handleClose: () => void;
}

const ReviewsReportEditModal: React.FC<ReviewsReportEditModalProps> = ({
  handleClose,
  reviewBoardId,
  report,
}) => {
  const [ text, setText ] = useState<string>('')

  const [
    submitPostReport,
  ] = usePostReviewActivityReportMutation()
  const [
    submitPatchReport,
  ] = usePatchReviewActivityReportMutation()

  useEffect(() => {
    if (report && report.text) {
      setText(report.text)
    }
  }, [ report ])

  const onClick = async () => {
    if (report?.id) {
      await submitPatchReport({ reviewBoardId, id: report.id, data: { text }})
      handleClose()
    } else {
      await submitPostReport({ reviewBoardId, data: { text }})
      handleClose()
    }
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitleContainer>
        Avancement de la semaine
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        <TipsContainer>
          Astuces :
          <li>
            Ne mettez pas votre prénom
          </li>
          <li>
            Faites une liste à puces
          </li>
          <li>
            Copiez-collez du mail de Laurent
          </li>
        </TipsContainer>
        <RichTextEditor
          value={text}
          onValueChange={setText}
          height="60vh"
        />
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          onClick={handleClose}
          variant="outlined"
        >
          Annuler
        </FormButton>
        <FormButton
          onClick={onClick}
          variant="contained"
        >
          Sauvegarder
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ReviewsReportEditModal
