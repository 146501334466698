/* eslint-disable @typescript-eslint/ban-ts-comment */
/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetClientGroupListQuery,
  useGetClientQuery,
  useGetIncidentListQuery,
  usePatchClientMutation,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Collapse,
  Pagination,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import IncidentsTableMobileCard from 'pages/IncidentsPage/IncidentsComponents/IncidentsTableMobileCard'
import IncidentsDeleteModal from 'pages/IncidentsPage/IncidentsComponents/IncidentsDeleteModal'
import ClientsDeleteModal from 'pages/ClientsPage/ClientsComponents/ClientsDeleteModal'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import Table from 'components/Table/Table'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ClientUpdateValidator,
  Client,
  IncidentListParams,
  Incident,
} from 'API/__generated__/Api'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'
import type { ChipColor } from 'types/ChipColor'

/* Types declaration -------------------------------------------------------- */
const clientSchema = Yup.object<Shape<ClientUpdateValidator>>({
  name: Yup.string().required('Ce champ est obligatoire'),
  groupId: Yup.string().nullable().required('Ce champ est obligatoire'),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
  address3: Yup.string().nullable(),
  zipCode: Yup.string().nullable(),
  city: Yup.string().nullable(),
  phone: Yup.string().test(
    'phone',
    'Le téléphone est invalide',
    (item = '') => !item || item.replaceAll(' ', '').length === 10,
  ).nullable(),
  email: Yup.string().email("L'email est invalide").nullable(),
  closingEmail: Yup.string().nullable(),
  paymentName: Yup.string().nullable(),
  paymentAddress1: Yup.string().nullable(),
  paymentAddress2: Yup.string().nullable(),
  paymentAddress3: Yup.string().nullable(),
  paymentZipCode: Yup.string().nullable(),
  paymentCity: Yup.string().nullable(),
  contactName1: Yup.string().nullable(),
  contactDirectPhone1: Yup.string().test(
    'direct-phone-1',
    'Le téléphone est invalide',
    (item = '') => !item || item.replaceAll(' ', '').length === 10,
  ).nullable(),
  contactEmail1: Yup.string().email("L'email est invalide").nullable(),
  contactName2: Yup.string().nullable(),
  contactDirectPhone2: Yup.string().test(
    'direct-phone-2',
    'Le téléphone est invalide',
    (item = '') => !item || item.replaceAll(' ', '').length === 10,
  ).nullable(),
  contactEmail2: Yup.string().email("L'email est invalide").nullable(),
  comment: Yup.string().nullable(),
  technicalComment: Yup.string().nullable(),
  tva: Yup.number().nullable(),
}).required()

type UpdateClientRequest = FormikContextType<ClientUpdateValidator>

const incidentsSchema = Yup.object({
  orderBy: Yup.mixed<DataName>().required(),
  order: Yup.mixed<Order>().required(),
  page: Yup.number().required(),
  limit: Yup.number().required(),
}).required()

type IncidentSchema = Yup.InferType<typeof incidentsSchema>

type IncidentForm = FormikContextType<IncidentSchema>

/* Styled components -------------------------------------------------------- */
const ColContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-start;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  margin-top: -15px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const DoubleContainer = styled(Container)`
  width: calc(100% / 2 - 10px);

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% - 10px);
  }
`

const TripleContainer = styled(Container)`
  width: calc(100% / 3 - 10px);

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / 3 - 10px);
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.2rem;
  cursor: pointer;
`

const PriorityChip = styled(ColoredSquareChip)`
  width: 100%;
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ClientPageProps {}

const ClientPage: React.FC<ClientPageProps> = () => {
  const navigate = useNavigate()
  const { clientId = '' } = useParams<{clientId: string}>()
  const [ deleteModal, setDeleteModal ] = useState<Client | null>()
  const [ deleteIncidentModal, setDeleteIncidentModal ] = useState<Incident | null>()
  const [ loading, setIsLoading ] = useState<boolean>(false)
  const [ expandMain, setExpandMain ] = useState<boolean>(true)
  const [ expandContact, setExpandContact ] = useState<boolean>(false)
  const [ expandAddress, setExpandAddress ] = useState<boolean>(false)
  const [ expandIncidents, setExpandIncidents ] = useState<boolean>(false)
  const [ totalRows, setTotalRows ] = useState<number | undefined>()

  const {
    currentData: client,
    isFetching: isFetchingClient,
  } = useGetClientQuery(clientId)
  const {
    currentData: groupList = [],
    isFetching: isFetchingGroupList,
  } = useGetClientGroupListQuery()
  const [
    submitUpdateClient,
  ] = usePatchClientMutation()

  const formikForm: UpdateClientRequest = useForm<ClientUpdateValidator>(
    {
      initialValues: {
        name: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        closingEmail: '',
        comment: '',
        contactDirectPhone1: '',
        contactDirectPhone2: '',
        contactEmail1: '',
        contactEmail2: '',
        contactName1: '',
        contactName2: '',
        email: '',
        paymentAddress1: '',
        paymentAddress2: '',
        paymentAddress3: '',
        paymentCity: '',
        paymentName: '',
        paymentZipCode: '',
        phone: '',
        technicalComment: '',
        // @ts-ignore
        tva: null,
        zipCode: '',
        // @ts-ignore
        groupId: null,
      },
      validationSchema: clientSchema,
    },
  )

  const incidentForm: IncidentForm = useForm<IncidentSchema>(
    {
      initialValues: {
        order: 'desc',
        orderBy: 'createdAt',
        page: 1,
        limit: 10,
      },
      validationSchema: incidentsSchema,
    },
  )

  const {
    currentData: incidentPagination,
    isFetching: isFetchingIncidentPagination,
  } = useGetIncidentListQuery(
    { ...incidentForm.values as unknown as IncidentListParams, clientIds: [ clientId ]},
    { skip: !expandIncidents },
  )

  useEffect(() => {
    if (!isFetchingClient && client) {
      formikForm.setValues(client as ClientUpdateValidator)
    }
  }, [ isFetchingClient ])

  useEffect(() => {
    if (incidentPagination?.meta?.total !== undefined && incidentPagination?.meta?.total !== totalRows) {
      setTotalRows(incidentPagination?.meta?.total)
    }
  }, [ incidentPagination, isFetchingIncidentPagination ])

  const setOrder = (order: Order) => incidentForm.setFieldValue('order', order)
  const setOrderBy = (orderBy: DataName) => incidentForm.setFieldValue('orderBy', orderBy)
  const setLimit = (limit: number) => incidentForm.setFieldValue('limit', limit)
  const setPage = (startIndex: number) => incidentForm.setFieldValue('page', startIndex)

  const getPriorityColor = (code: string): ChipColor => {
    switch (code) {
      case 'Elevé':
        return 'red'
      case 'Confort':
        return 'green'
      default:
        return 'yellow'
    }
  }

  const cols: ColumnHeader[] = [
    {
      id: 'family.name',
      label: 'Famille',
      sortId: 'familyId',
    },
    {
      id: 'comment',
      label: 'Description',
    },
    {
      id: 'createdAt',
      label: 'Date de création',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
    },
    {
      id: 'assignedTo.name',
      label: 'Affectation',
      sortId: 'userId',
    },
    {
      id: 'status.name',
      label: 'État',
      sortId: 'statusId',
    },
    {
      id: 'priority.name',
      label: 'Priorité',
      sortId: 'priorityId',
      render: (priority: string) => (
        <PriorityChip color={getPriorityColor(priority)}>
          {priority}
        </PriorityChip>
      ),
    },
  ]

  const onEditClient = () => {
    setIsLoading(true)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitUpdateClient({ id: client?.id || '', data: formikForm.values })
      })
      .then((response) => {
        if (isApiError(response)) {
          toast.error("Une erreur est survenue lors de l'enregistrement du client.")
        } else if (response) {
          navigate(-1)
        }
        setIsLoading(false)
      }).catch(console.error)
  }

  const onEditIncident = (id: number) => {
    navigate(`/incidents/${id}`)
  }

  return (
    <React.Fragment>
      <Form form={formikForm}>
        <RouteTitle title={client?.name || ''} />
        <LargeTitle>
          {client?.name}
          <ButtonsContainer>
            <LongButton
              variant="contained"
              disabled={!client}
              onClick={onEditClient}
            >
              Enregistrer
            </LongButton>
            <LongButton
              color="error"
              variant="contained"
              disabled={!client}
              onClick={() => setDeleteModal(client)}
            >
              Supprimer le client
            </LongButton>
          </ButtonsContainer>
        </LargeTitle>
        <LoadingOverlay isLoading={isFetchingClient || loading}>
          <Card>
            <CardContent>
              <Title onClick={() => setExpandMain(!expandMain)}>
                Informations principales
                <DropdownArrow expanded={expandMain.toString()} />
              </Title>
              <Collapse
                in={expandMain}
                timeout="auto"
                unmountOnExit
              >
                <LineContainer>
                  <TripleContainer>
                    <FormBoldTitle required>
                      Nom
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Nom"
                      name="name"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle required>
                      Groupe
                    </FormBoldTitle>
                    <AutocompleteField
                      name="groupId"
                      options={groupList.filter((p) => p.name).map((p) => p.id)}
                      getOptionLabel={(option) => groupList.find((p) => p.id === option)?.name || ''}
                      disabled={isFetchingGroupList}
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      TVA
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="TVA"
                      name="tva"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Téléphone
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Téléphone"
                      name="phone"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Email
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Email"
                      name="email"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Email clotûre
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Email clotûre"
                      name="closingEmail"
                      size="small"
                    />
                  </TripleContainer>
                </LineContainer>
                <br />
                <LineContainer>
                  <DoubleContainer>
                    <FormBoldTitle>
                      Description
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Description"
                      name="comment"
                      size="small"
                      multiline
                      rows={6}
                    />
                  </DoubleContainer>
                  <DoubleContainer>
                    <FormBoldTitle>
                      Technique
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Technique"
                      name="technicalComment"
                      size="small"
                      multiline
                      rows={6}
                    />
                  </DoubleContainer>
                </LineContainer>
              </Collapse>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent>
              <Title onClick={() => setExpandContact(!expandContact)}>
                Contact
                <DropdownArrow expanded={expandContact.toString()} />
              </Title>
              <Collapse
                in={expandContact}
                timeout="auto"
                unmountOnExit
              >
                <LineContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Responsable 1
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Responsable 1"
                      name="contactName1"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Ligne directe 1
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Ligne directe 1"
                      name="contactDirectPhone1"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Email responsable 1
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Email responsable 1"
                      name="contactEmail1"
                      size="small"
                    />
                  </TripleContainer>
                </LineContainer>
                <br />
                <LineContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Responsable 2
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Responsable 2"
                      name="contactName2"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Ligne directe 2
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Ligne directe 2"
                      name="contactDirectPhone2"
                      size="small"
                    />
                  </TripleContainer>
                  <TripleContainer>
                    <FormBoldTitle>
                      Email responsable 2
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Email responsable 2"
                      name="contactEmail2"
                      size="small"
                    />
                  </TripleContainer>
                </LineContainer>
              </Collapse>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent>
              <Title onClick={() => setExpandAddress(!expandAddress)}>
                Adresse & Facturation
                <DropdownArrow expanded={expandAddress.toString()} />
              </Title>
              <Collapse
                in={expandAddress}
                timeout="auto"
                unmountOnExit
              >
                <ColContainer>
                  <DoubleContainer>
                    <FormBoldTitle>
                      Adresse 1
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse 1"
                      name="address1"
                      size="small"
                    />
                    <FormBoldTitle>
                      Adresse 2
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse 2"
                      name="address2"
                      size="small"
                    />
                    <FormBoldTitle>
                      Adresse 3
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse 3"
                      name="address3"
                      size="small"
                    />
                    <FormBoldTitle>
                      Code postal
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Code postal"
                      name="zipCode"
                      size="small"
                    />
                    <FormBoldTitle>
                      Ville
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Ville"
                      name="city"
                      size="small"
                    />
                  </DoubleContainer>
                  <DoubleContainer>
                    <FormBoldTitle>
                      Adresse facturation 1
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse facturation 1"
                      name="paymentAddress1"
                      size="small"
                    />
                    <FormBoldTitle>
                      Adresse facturation 2
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse facturation 2"
                      name="paymentAddress2"
                      size="small"
                    />
                    <FormBoldTitle>
                      Adresse facturation 3
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse facturation 3"
                      name="paymentAddress3"
                      size="small"
                    />
                    <FormBoldTitle>
                      Code postal facturation
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Code postal facturation"
                      name="paymentZipCode"
                      size="small"
                    />
                    <FormBoldTitle>
                      Ville facturation
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Ville facturation"
                      name="paymentCity"
                      size="small"
                    />
                    <FormBoldTitle>
                      Nom de l'adresse de facturation
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Nom adresse facturation"
                      name="paymentName"
                      size="small"
                    />
                  </DoubleContainer>
                </ColContainer>
              </Collapse>
            </CardContent>
          </Card>
          <br />
        </LoadingOverlay>
      </Form>
      <Card>
        <CardContent>
          <Title onClick={() => setExpandIncidents(!expandIncidents)}>
            Incidents
            <DropdownArrow expanded={expandIncidents.toString()} />
          </Title>
          <Collapse
            in={expandIncidents}
            timeout="auto"
            unmountOnExit
          >
            <LoadingOverlay isLoading={isFetchingIncidentPagination}>
              <TableCardContentContainer>
                <Table
                  rows={incidentPagination?.data ?? []}
                  setRows={() => null}
                  colHeaders={cols}
                  defaultOrder={incidentForm.initialValues}
                  onRowClick={(row) => onEditIncident((row as Incident).id)}
                  limit={incidentForm.initialValues.limit}
                  sorting={{ setOrder, setOrderBy }}
                  pagination={{ totalRows, setLimit, setPage }}
                  resultsPerPage={[ 10, 25, 50, 100, totalRows ?? 200 ]}
                />
              </TableCardContentContainer>
              <MobileCardContainer>
                <Pagination
                  count={incidentPagination?.meta.lastPage}
                  page={incidentForm.values.page}
                  onChange={(e, page) => setPage(page)}
                />
                {
                  incidentPagination?.data?.map((incident) => (
                    <IncidentsTableMobileCard
                      key={incident.id}
                      incident={incident}
                      onEdit={() => onEditIncident(incident.id)}
                      onDelete={() => setDeleteIncidentModal(incident)}
                    />
                  ))
                }
                <Pagination
                  count={incidentPagination?.meta.lastPage}
                  page={incidentForm.values.page}
                  onChange={(e, page) => setPage(page)}
                />
              </MobileCardContainer>
            </LoadingOverlay>
          </Collapse>
        </CardContent>
      </Card>
      {
        deleteModal &&
          <ClientsDeleteModal
            open
            client={deleteModal}
            handleClose={() => {navigate('/clients'); setDeleteModal(null)}}
          />
      }
      {
        deleteIncidentModal &&
          <IncidentsDeleteModal
            open
            incident={deleteIncidentModal}
            handleClose={() => setDeleteIncidentModal(null)}
          />
      }
    </React.Fragment>
  )
}

export default ClientPage
