/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { Project } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`

/* Component declaration ---------------------------------------------------- */
interface ProjectsTableMobileCardProps {
  project: Project;
  onDelete: () => void;
  onEdit: () => void;
}

const ProjectsTableMobileCard: React.FC<ProjectsTableMobileCardProps> = ({
  project,
  onDelete,
  onEdit,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          {project.name}
        </TitleContainer>
        {`Code Projet : ${project.id || ''}`}
        <br />
        {`Client : ${project.client?.name || ''}`}
        <br />
        {`Logiciel : ${project.software?.name || ''}`}
        <br />
        {`Statut : ${project.status?.name || ''}`}
        <br />
        <ButtonContainer>
          <Button
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            onClick={onEdit}
          >
            Modifier
          </Button>
        </ButtonContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default ProjectsTableMobileCard
