/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import {
  ContentCopyRounded,
  DeleteOutlined,
} from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import NumberField from 'components/FieldWithInputAdornment/NumberField'

/* Type imports ------------------------------------------------------------- */
import type {
  Client,
  Imputation,
  Project,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DatePicker = styled(FormikDatePicker)`
  .MuiInputBase-root {
    height: 40px;
  }
`

const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  align-items: flex-start;

  @media ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 0px;
    margin-top: -10px;
  }
`

const Container = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: calc(50% - 5px);
  }
`

const ClientContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% - 100px - 170px - 20px);
  }
`

const DateContainer = styled.div`
  min-width: 170px;
  width: 170px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const HourContainer = styled.div`
  min-width: 100px;
  width: 100px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const CommentContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% - 138px - 10px);
  }
`

const ButtonsContainer = styled.div`
  min-width: 138px;
  width: 138px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${(props) => props.theme.media.mobile.main} {
    justify-content: flex-start;
    gap: 10px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesModalProps {
  index: number;
  projectList: Project[];
  isFetchingProjectList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  imputationList: Imputation[];
  onDeleteClick: () => void;
  onDuplicateClick: () => void;
}

const ActivitiesModalItem: React.FC<ActivitiesModalProps> = ({
  index,
  imputationList = [],
  projectList = [],
  isFetchingProjectList,
  clientList = [],
  isFetchingClientList,
  onDeleteClick,
  onDuplicateClick,
}) => {

  const getFullName = (value?: Project | Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <ActivityContainer>
      <RowContainer>
        <DateContainer>
          <FormBoldTitle
            smaller
            required
          >
            Date
          </FormBoldTitle>
          <DatePicker name={`activities[${index}].date`} />
        </DateContainer>
        <HourContainer>
          <FormBoldTitle
            smaller
            required
          >
            Durée (h)
          </FormBoldTitle>
          <NumberField
            placeholder="Heures"
            name={`activities[${index}].nbHours`}
            size="small"
          />
        </HourContainer>
        <ClientContainer>
          <FormBoldTitle smaller>
            Client
          </FormBoldTitle>
          <AutocompleteField
            name={`activities[${index}].clientId`}
            options={clientList.filter((p) => p.name).map((p) => p.id)}
            getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
            disabled={isFetchingClientList}
            size="small"
          />
        </ClientContainer>
        <Container>
          <FormBoldTitle
            required
            smaller
          >
            Projet
          </FormBoldTitle>
          <AutocompleteField
            name={`activities[${index}].projectId`}
            options={projectList.filter((p) => p.name && p.statusId !== 7).map((p) => p.id)}
            getOptionLabel={(option) => getFullName(projectList.find((p) => p.id === option))}
            disabled={isFetchingProjectList}
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle
            required
            smaller
          >
            Imputation
          </FormBoldTitle>
          <AutocompleteField
            name={`activities[${index}].imputationId`}
            options={imputationList.filter((p) => p.name).map((p) => p.imputationId)}
            getOptionLabel={(option) => imputationList.find((p) => p.imputationId === option)?.name || ''}
            disabled={isFetchingProjectList}
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        <CommentContainer>
          <FormBoldTitle
            smaller
            required
          >
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Commentaire"
            name={`activities[${index}].comment`}
            size="small"
            multiline
          />
        </CommentContainer>
        <ButtonsContainer>
          <FormBoldTitle smaller>
            Actions
          </FormBoldTitle>
          <ButtonsRow>
            <Button
              variant="outlined"
              color="error"
              onClick={onDeleteClick}
            >
              <DeleteOutlined />
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onDuplicateClick}
            >
              <ContentCopyRounded />
            </Button>
          </ButtonsRow>
        </ButtonsContainer>
      </RowContainer>
    </ActivityContainer>
  )
}

export default ActivitiesModalItem
