/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  initialValues,
  type EnvironmentSchema,
} from 'pages/EnvironmentUrlsPage/EnvironmentUrlsPage'

/* Redux slice -------------------------------------------------------------- */
const environmentUrlsFormSlice = createSlice(
  {
    name: 'environmentUrlsForm',
    initialState: initialValues,
    reducers: {
      setEnvironmentUrlsForm: (state, { payload }: PayloadAction<EnvironmentSchema>) => {
        return payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setEnvironmentUrlsForm } = environmentUrlsFormSlice.actions

export default environmentUrlsFormSlice.reducer

export const getEnvironmentUrlsForm = (state: RootState): EnvironmentSchema => {
  return state.environmentUrlsForm
}
