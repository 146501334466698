/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetClientListQuery,
  useGetIncidentAssignmentListQuery,
  useGetIncidentFamilyListQuery,
  useGetIncidentStatusListQuery,
  useGetIncidentQuery,
  useGetIncidentPriorityListQuery,
  usePostIncidentFilesMutation,
  useDeleteIncidentFileMutation,
  usePatchIncidentMutation,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  IconButton,
} from '@mui/material'
import {
  CircleRounded,
  DeleteRounded,
} from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import IncidentsDeleteModal from 'pages/IncidentsPage/IncidentsComponents/IncidentsDeleteModal'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import IncidentActionModal from './IncidentComponents/IncidentActionModal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  IncidentUpdateValidator,
  Incident,
  Client,
  IncidentFileStoreValidator,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const incidentSchema = Yup.object<Shape<IncidentUpdateValidator>>({
  clientId: Yup.string().nullable().required('Ce champ est obligatoire'),
  comment: Yup.string(),
  statusId: Yup.string().nullable().required('Ce champ est obligatoire'),
  ticketUrl: Yup.string(),
  contact: Yup.string().required('Ce champ est obligatoire'),
  familyId: Yup.number().nullable().required('Ce champ est obligatoire'),
  subFamilyId: Yup.number().nullable().required('Ce champ est obligatoire'),
  priorityId: Yup.number().nullable().required('Ce champ est obligatoire'),
  userId: Yup.number().nullable().required('Ce champ est obligatoire'),
  resolvedComment: Yup.string().nullable(),
}).required()

type UpdateIncidentRequest = FormikContextType<IncidentUpdateValidator>

/* Styled components -------------------------------------------------------- */
const ColContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 3 - 10px);
  margin-top: -15px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / 3 - 10px);
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.2rem;
`

const GridContainer = styled.div`
  margin-top: 10px;
  display: grid;
  gap: 30px;
  align-items: stretch;
  justify-content: start;

  grid-template-columns: repeat(auto-fit, 100px);

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: repeat(auto-fit, 90px);
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const DeleteButtonContainer = styled(IconButton)`
  position: absolute;
  right: 2px;
  top: 95px;
  z-index: 10;
  color: ${(props) => props.theme.palette.error.main};
  background-color: rgba(255, 255, 255, 0.225);
`

interface LineContainerProps {
  last: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.palette.primary.main};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '15px'};

  .MuiPaper-root {
    width: 100%;
  }
`

const Dot = styled.div`
  display: flex;
  align-items: center;
  margin-left: -31px;
  margin-right: 10px;
`

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: .9rem;
  padding: 10px;
`

const DateContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bolder;
`

const HistoryTitleContainer = styled.div`
  display: flex;
  gap: 20px;
`

const ClientContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 10px;
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

/* Component declaration ---------------------------------------------------- */
interface IncidentPageProps {}

const IncidentPage: React.FC<IncidentPageProps> = () => {
  const navigate = useNavigate()
  const { incidentId } = useParams<{incidentId: string }>()
  const [ deleteModal, setDeleteModal ] = useState<Incident | null>()
  const [ actionModal, setActionModal ] = useState<number | null>(null)
  const [ loading, setIsLoading ] = useState<boolean>(false)

  const {
    currentData: incident,
    isFetching: isFetchingIncident,
  } = useGetIncidentQuery(parseInt(incidentId ?? '0'))
  const {
    currentData: userList = [],
    isFetching: isFetchingUserList,
  } = useGetIncidentAssignmentListQuery()
  const {
    currentData: familyList = [],
    isFetching: isFetchingFamilyList,
  } = useGetIncidentFamilyListQuery()
  const {
    currentData: statusList = [],
    isFetching: isFetchingStatusList,
  } = useGetIncidentStatusListQuery()
  const {
    currentData: priorityList = [],
    isFetching: isFetchingPriorityList,
  } = useGetIncidentPriorityListQuery()
  const {
    currentData: clientList = [],
    isFetching: isFetchingClientList,
  } = useGetClientListQuery()
  const [
    submitUpdateIncident,
  ] = usePatchIncidentMutation()
  const [
    submitNewFiles,
  ] = usePostIncidentFilesMutation()
  const [
    submitDeleteFile,
  ] = useDeleteIncidentFileMutation()

  const formikForm: UpdateIncidentRequest = useForm<IncidentUpdateValidator>(
    {
      initialValues: {
        accountedAt: '',
        clientId: '',
        clientRelaunch: false,
        comment: '',
        contact: '',
        familyId: 0,
        priorityId: 0,
        statusId: '',
        subFamilyId: 0,
        ticketUrl: '',
        userId: 0,
        resolvedComment: '',
      },
      validationSchema: incidentSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingIncident && incident) {
      formikForm.setValues(incident as IncidentUpdateValidator)
    }
  }, [ isFetchingIncident ])

  const onEditIncident = () => {
    setIsLoading(true)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitUpdateIncident({ id: incident?.id || 0, data: formikForm.values })
      })
      .then((response) => {
        if (isApiError(response)) {
          toast.error("Une erreur est survenue lors de l'enregistrement de l'incident.")
        } else if (response) {
          navigate(-1)
        }
        setIsLoading(false)
      }).catch(console.error)
  }

  const getSubFamilyList = () => {
    const subFamilL = familyList.find((f) => formikForm.values.familyId === f.id)?.subFamilies ?? []

    if (formikForm.values.subFamilyId && !subFamilL.find((s) => s.id === formikForm.values.subFamilyId)) {
      formikForm.setFieldValue('subFamilyId', '')
    }

    return subFamilL
  }

  const getFullName = (value?: Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsLoading(true)
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      const data: IncidentFileStoreValidator = { files: []}

      Object.values(e.target.files).forEach((file) => {
        file && data.files.push(file)
      })

      submitNewFiles({ incidentId: incident?.id || 0, data })
        .then((response) => {
          if (isApiError(response)) {
            toast.error("Une erreur est survenue lors de l'ajout de vos pièces jointes.")
          }
          setIsLoading(false)
        }).catch(console.error)
    }
  }

  const onFileDeleteClick = (id: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setIsLoading(true)
    submitDeleteFile({ incidentId: incident?.id || 0, id }).then(() => setIsLoading(false)).catch(console.error)
  }

  return (
    <Form form={formikForm}>
      <RouteTitle title={`Incident N° ${incidentId}`} />
      <LargeTitle>
        {`Incident N°${incidentId}`}
        <ButtonsContainer>
          <LongButton
            variant="contained"
            disabled={!incident}
            onClick={onEditIncident}
          >
            Enregistrer
          </LongButton>
          <LongButton
            variant="contained"
            disabled={!incident}
            onClick={() => setActionModal(incident?.id || 0)}
          >
            Ajouter une action
          </LongButton>
          <LongButton
            color="error"
            variant="contained"
            disabled={!incident}
            onClick={() => setDeleteModal(incident)}
          >
            Supprimer l'incident
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <LoadingOverlay isLoading={isFetchingIncident || loading}>
        <Card>
          <CardContent>
            <Title>
              Informations
            </Title>
            <ColContainer>
              <Container>
                <FormBoldTitle required>
                  Client
                </FormBoldTitle>
                <ClientContainer>
                  <AutocompleteField
                    name="clientId"
                    options={clientList.filter((p) => p.name).map((p) => p.id)}
                    getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
                    disabled={isFetchingClientList}
                    size="small"
                  />
                  <Link href={`/clients/${formikForm.values.clientId}`}>
                    Fiche client
                  </Link>
                </ClientContainer>
                <FormBoldTitle required>
                  Personne à contacter
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Personne à contacter"
                  name="contact"
                  size="small"
                />
                <FormBoldTitle required>
                  Affectation
                </FormBoldTitle>
                <AutocompleteField
                  name="userId"
                  options={userList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => userList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingUserList}
                  size="small"
                />
                <FormBoldTitle required>
                  État
                </FormBoldTitle>
                <AutocompleteField
                  name="statusId"
                  options={statusList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => statusList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingStatusList}
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle required>
                  Famille
                </FormBoldTitle>
                <AutocompleteField
                  name="familyId"
                  options={familyList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => familyList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingFamilyList}
                  size="small"
                />
                <FormBoldTitle required>
                  Sous-Famille
                </FormBoldTitle>
                <AutocompleteField
                  name="subFamilyId"
                  options={getSubFamilyList().filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => getSubFamilyList().find((p) => p.id === option)?.name || ''}
                  disabled={getSubFamilyList().length === 0}
                  size="small"
                />
                <FormBoldTitle required>
                  Priorité
                </FormBoldTitle>
                <AutocompleteField
                  name="priorityId"
                  options={priorityList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => priorityList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingPriorityList}
                  size="small"
                />
                <FormBoldTitle>
                  Lien Mantis
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Lien Mantis"
                  name="ticketUrl"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle>
                  Description
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Description"
                  name="comment"
                  size="small"
                  multiline
                  rows={6.2}
                />
                <FormBoldTitle>
                  Commentaire résolution
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Commentaire résolution"
                  name="resolvedComment"
                  size="small"
                  multiline
                  rows={4}
                />
              </Container>
            </ColContainer>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Title>
              Pièces-jointes
              <AttachmentButton onChange={handleOnFileChange}>
                <Button
                  variant="contained"
                  component="span"
                >
                  Ajouter une pièce jointe
                </Button>
              </AttachmentButton>
            </Title>
            <GridContainer>
              {
                incident?.files?.map((document, index) => (
                  <ImageContainer key={`${document.name}-${index}`}>
                    <VignetteRendererWithFilename
                      document={document}
                      onClick={() => window.open(`${document.path}`, '_blank', 'noreferrer')}
                      width="100%"
                      height="130px"
                    />
                    <DeleteButtonContainer
                      onClick={onFileDeleteClick(document.id)}
                      size="small"
                    >
                      <DeleteRounded />
                    </DeleteButtonContainer>
                  </ImageContainer>
                ))
              }
            </GridContainer>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Title>
              Historique
            </Title>
            <br />
            {
              incident?.history && incident.history.map((history, index) => (
                <LineContainer
                  key={`${history.id}-${index}`}
                  last={index === (incident.history ?? []).length - 1}
                  id={`${history.id}-${index}`}
                >
                  <Dot>
                    <CircleRounded
                      color="secondary"
                      fontSize="small"
                    />
                  </Dot>
                  <Card>
                    <CardContentContainer>
                      <HistoryTitleContainer>
                        <DateContainer>
                          {DateUtils.APIStrToLocalDateString(history.createdAt, { hour: '2-digit', minute: '2-digit' })}
                        </DateContainer>
                        {`${history.userInfo?.firstNameWithInitial || ''} : ${history.actionName}`}
                      </HistoryTitleContainer>
                      {history.comment && `Commentaire : ${history.comment}`}
                    </CardContentContainer>
                  </Card>
                </LineContainer>
              ))
            }
          </CardContent>
        </Card>
      </LoadingOverlay>
      {
        deleteModal &&
          <IncidentsDeleteModal
            open
            incident={deleteModal}
            handleClose={() => {navigate('/incidents'); setDeleteModal(null)}}
          />
      }
      {
        actionModal &&
          <IncidentActionModal
            open
            incidentId={actionModal}
            handleClose={() => setActionModal(null)}
          />
      }
    </Form>
  )
}

export default IncidentPage
