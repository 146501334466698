/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useAppDispatch } from 'store/hooks'
import { setAuth } from 'store/slices/authSlice'
import { useLoginMutation } from 'store/api'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  LoginValidator,
  User,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const LoginFormSchema = Yup.object().shape({
  email: Yup.string().required('Email invalide'),
  password: Yup.string().required('Mot de passe invalide'),
})

/* Component declaration ---------------------------------------------------- */
interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  const [
    tryLogin,
  ] = useLoginMutation()

  const onSubmit = (values: LoginValidator, { setSubmitting, setErrors }: FormikHelpers<LoginValidator> ): void | Promise<void> => {
    setErrorMessage(null)
    tryLogin(values)
      .then((pResult) => {
        setSubmitting(false)
        if (isApiError(pResult)) {
          if (pResult.error.status === 404) {
            setErrors({ email: 'Mauvais identifiant ou mot de passe.', password: 'Mauvais identifiant ou mot de passe.' })
          } else {
            setErrorMessage('Erreur lors de la connexion.')
          }
        } else if (isApiResponse<User>(pResult)) {
          dispatch(setAuth((pResult.data)))
        }
      })
      .catch(console.error)
  }

  const formikForm = useForm<LoginValidator>(
    {
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: onSubmit,
      validationSchema: LoginFormSchema,
    },
  )

  const onFirstLoginClick = () => {
    navigate('/premiere-connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          {'Bienvenue sur '}
          <b>
            Unlimited
          </b>
        </AuthWelcomeMessage>
        <AuthErrorMessage>
          {errorMessage}
        </AuthErrorMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              Email
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Email"
              name="email"
              autoComplete="username"
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <FormBoldTitle>
              Mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              name="password"
              autoComplete="current-password"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            {
              formikForm.isSubmitting ?
                `Connexion en cours...` :
                `Se connecter`
            }
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onFirstLoginClick}
          >
            Première connexion
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default LoginPage
