/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  Project,
  User,
  Client,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

interface ContainerProps {
  isAdmin: boolean;
}

const Container = styled.div<ContainerProps>`
  width: calc(100% /  ${(props) => props.isAdmin ? 5 : 4} - 10px);

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
    margin-top: -15px;
  }

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / ${(props) => props.isAdmin ? 3 : 4} - 10px);
  }
`

const DatePicker = styled(FormikDatePicker)`
  .MuiInputBase-root {
    height: 40px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesFiltersProps {
  projectList: Project[];
  isFetchingProjectList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  userList: User[];
  isFetchingUserList: boolean;
  isAdmin: boolean;
}

const ActivitiesFilters: React.FC<ActivitiesFiltersProps> = ({
  userList,
  isFetchingUserList,
  projectList,
  isFetchingProjectList,
  clientList,
  isFetchingClientList,
  isAdmin,
}) => {

  const getFullName = (value?: Project | Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <SelectFilterContainer>
      {
        isAdmin &&
          <Container isAdmin={isAdmin}>
            <FormBoldTitle>
              Acteur
            </FormBoldTitle>
            <AutocompleteField
              name="userIds"
              options={userList.filter((p) => p.userInfo?.firstNameWithInitial).map((p) => p.id)}
              getOptionLabel={(option) => userList.find((p) => p.id === option)?.userInfo?.firstNameWithInitial || ''}
              disabled={isFetchingUserList}
              size="small"
              multiple
            />
          </Container>
      }
      <Container isAdmin={isAdmin}>
        <FormBoldTitle>
          Client
        </FormBoldTitle>
        <AutocompleteField
          name="clientIds"
          options={clientList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
          disabled={isFetchingClientList}
          size="small"
          multiple
        />
      </Container>
      <Container isAdmin={isAdmin}>
        <FormBoldTitle>
          Projet
        </FormBoldTitle>
        <AutocompleteField
          name="projectIds"
          options={projectList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => getFullName(projectList.find((p) => p.id === option))}
          disabled={isFetchingProjectList}
          size="small"
          multiple
        />
      </Container>
      <Container isAdmin={isAdmin}>
        <FormBoldTitle>
          Date de début
        </FormBoldTitle>
        <DatePicker name="startDate" />
      </Container>
      <Container isAdmin={isAdmin}>
        <FormBoldTitle>
          Date de fin
        </FormBoldTitle>
        <DatePicker name="endDate" />
      </Container>
    </SelectFilterContainer>
  )
}

export default ActivitiesFilters
