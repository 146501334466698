/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  initialValues,
  type ClientSchema,
} from 'pages/ClientsPage/ClientsPage'

/* Redux slice -------------------------------------------------------------- */
const clientsFormSlice = createSlice(
  {
    name: 'clientsForm',
    initialState: initialValues,
    reducers: {
      setClientsForm: (state, { payload }: PayloadAction<ClientSchema>) => {
        return payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setClientsForm } = clientsFormSlice.actions

export default clientsFormSlice.reducer

export const getClientsForm = (state: RootState): ClientSchema => {
  return state.clientsForm
}
