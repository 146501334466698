/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  initialValues,
  type ActivitySchema,
} from 'pages/ActivitiesPage/ActivitiesPage'

/* Redux slice -------------------------------------------------------------- */
const activitiesFormSlice = createSlice(
  {
    name: 'activitesForm',
    initialState: initialValues,
    reducers: {
      setActivitiesForm: (state, { payload }: PayloadAction<ActivitySchema>) => {
        return payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setActivitiesForm } = activitiesFormSlice.actions

export default activitiesFormSlice.reducer

export const getActivitiesForm = (state: RootState): ActivitySchema => {
  return state.activitiesForm
}
