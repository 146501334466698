/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useTheme } from '@emotion/react'
import DateUtils from 'helpers/DateUtils'
import {
  useGetActivityListQuery,
  useGetSettingsQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Tooltip,
} from '@mui/material'
import Calendar from 'react-activity-calendar'

/* Type imports ------------------------------------------------------------- */
import type {
  Activity as CalendarActivity,
  ColorScale,
  ThemeInput,
} from 'react-activity-calendar'
import type { Activity } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
interface Modal {
  activities: Activity[];
  days: string[];
}

/* Internal Variables ------------------------------------------------------- */
const firstDay = new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0]
const lastDay = new Date(new Date().getFullYear(), 11, 32).toISOString().split('T')[0]

const labels = {
  months: [
    'Janv',
    'Fév',
    'Mar',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sep',
    'Oct',
    'Nov',
    'Déc',
  ],
  weekdays: [
    'Dim',
    'Lun',
    'Mar',
    'Mer',
    'Jeu',
    'Ven',
    'Sam',
  ],
  totalCount: '{{count}} activités en {{year}}',
  legend: {
    less: 'Non rempli / Heures manquantes / Complet / Heures supplémentaires / Erreur',
    more: '',
  },
}

/* Styled components -------------------------------------------------------- */
const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px 0px;
  margin-left: 30px;
  gap: 10px;

  &.multi {
    cursor: crosshair;
  }

  rect {
    :hover {
      fill: ${(props) => props.theme.colors.darkgrey};
    }
  }

  .rect-selected {
    fill: black;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesCalendarProps {
  isAdmin: boolean;
  userIds?: number[];
  setModal: (values: Modal) => void;
}

const ActivitiesCalendar: React.FC<ActivitiesCalendarProps> = ({ setModal, userIds = [], isAdmin }) => {
  const emotionTheme = useTheme()
  const [ multi, setMulti ] = useState<boolean>(false)
  const [ selected, setSelected ] = useState<CalendarActivity[]>([])

  const {
    currentData: userActivity,
    isFetching: isFetchingUserActivity,
  } = useGetActivityListQuery(
    { startDate: firstDay, endDate: lastDay, order: 'asc', userIds },
    { refetchOnMountOrArgChange: isAdmin },
  )
  const {
    currentData: settings,
    isFetching: isFetchingSettings,
  } = useGetSettingsQuery()

  useEffect(() => {
    if (!multi) {
      setSelected([])
      const elem = Array.from(document.getElementsByClassName('rect-selected'))
      elem.forEach((el) => el.classList.remove('rect-selected'))
    }
  }, [ multi ])

  const data = useMemo(() => {
    // 0: no data - 1: not enough - 2: enough - 3: too much - 4: > 10
    const findLevel = (count: number, date: string) => {
      const day = new Date(date).getDay()

      if (count === DateUtils.getCalendarHourAmount(day, settings)) return 2
      if (count < DateUtils.getCalendarHourAmount(day, settings)) return 1
      if (count > 10) return 4

      return 3
    }

    if (!isFetchingUserActivity) {
      const activities: CalendarActivity[] = []
      if (!activities.find((ac) => ac.date === firstDay)) {
        activities.push({ date: firstDay, count: 0, level: 0 })
      }
      userActivity?.data.forEach((activity) => {
        const index = activities.findIndex((ac) => ac.date === DateUtils.APIStrToShortDate(activity.date))
        if (index !== -1) {
          activities[index].count += activity.nbHours
          activities[index].level = findLevel(activities[index].count, activities[index].date)
        } else {
          activities.push({ date: DateUtils.APIStrToShortDate(activity.date), count: activity.nbHours, level: findLevel(activity.nbHours, activity.date) })
        }
      })
      if (!activities.find((ac) => ac.date === lastDay)) {
        activities.push({ date: lastDay, count: 0, level: 0 })
      }
      activities.sort((a, b) => a.date.localeCompare(b.date))
      return activities
    }
    return []
  }, [ isFetchingUserActivity, isFetchingSettings ])

  const onBlockClick = (activity: CalendarActivity) => {
    if (!multi) {
      setModal({ activities: userActivity?.data.filter((ac) => DateUtils.APIStrToShortDate(ac.date) === activity.date) ?? [], days: [ activity.date ]})
      return
    }
    if (selected.length === 0) {
      setSelected([ activity ])
      const elem = document.querySelector(`rect[data-date="${activity.date}"]`)
      elem?.classList.add('rect-selected')
      return
    }
    if (selected.length === 1) {
      if (selected[0]?.date === activity.date) {
        setSelected([])
        const elem = document.querySelector(`rect[data-date="${activity.date}"]`)
        elem?.classList.remove('rect-selected')
      } else {
        let newArr: CalendarActivity[] = [ selected[0] ]
        if (selected[0].date.localeCompare(activity.date) === 1) {
          newArr = [ activity, newArr[0] ]
        } else {
          newArr.push(activity)
        }
        setSelected(newArr)
        for (let startDate = new Date(newArr[0].date); startDate <= new Date(newArr[1].date); startDate.setDate(startDate.getDate() + 1)) {
          const elem = document.querySelector(`rect[data-date="${DateUtils.dateToShortAPIStr(startDate)}"]`)
          elem?.classList.add('rect-selected')
        }
      }
      return
    }
    let last: CalendarActivity | undefined = undefined
    if (selected[0]?.date === activity.date) {
      last = selected[1]
    } else if (selected[1]?.date === activity.date) {
      last = selected[0]
    } else {
      last = activity
    }
    setSelected([ last ])
    const elem = Array.from(document.getElementsByClassName('rect-selected'))
    elem.forEach((el) => el.classList.remove('rect-selected'))
    const newElem = document.querySelector(`rect[data-date="${last.date}"]`)
    newElem?.classList.add('rect-selected')
  }

  const onMultiValidateClick = () => {
    const start = new Date(selected[0].date)
    const end = new Date(selected[1].date)
    const days = []

    while (start <= end) {
      if (DateUtils.getCalendarHourAmount(start.getDay(), settings) !== 0) {
        days.push(new Date(start).toISOString())
      }

      start.setDate(start.getDate() + 1)
    }

    setModal({
      activities: userActivity?.data.filter((ac) => DateUtils.APIStrToShortDate(ac.date) >= selected[0].date && DateUtils.APIStrToShortDate(ac.date) <= selected[1].date) ?? [],
      days,
    })

    setMulti(false)
  }

  const getTheme = (): ThemeInput => {
    const palette: ColorScale = [ emotionTheme.colors.grey, emotionTheme.palette.info.main, emotionTheme.palette.secondary.main, emotionTheme.palette.primary.main, emotionTheme.palette.error.main ]

    return ({ dark: palette, light: palette })
  }

  return (
    <CalendarContainer className={` ${multi ? 'multi' : ''}`}>
      <Calendar
        data={data}
        loading={isFetchingUserActivity}
        weekStart={1}
        showWeekdayLabels
        hideTotalCount
        labels={labels}
        eventHandlers={{ onClick: () => (activity) => onBlockClick(activity) }}
        renderBlock={
          (block, activity) => (
            <Tooltip
              arrow
              disableInteractive
              title={`${activity.count}h le ${DateUtils.APIStrToLocalDateString(activity.date, { day: '2-digit', weekday: 'long', month: '2-digit' })}`}
            >
              {block}
            </Tooltip>
          )
        }
        theme={getTheme()}
      />
      <ButtonContainer>
        <Button
          variant={multi ? 'outlined' : 'contained'}
          onClick={() => setMulti(!multi)}
        >
          {multi ? 'Annuler' : 'Sélection multiple'}
        </Button>
        <Button
          variant="contained"
          disabled={!multi || selected.length !== 2}
          onClick={onMultiValidateClick}
        >
          Valider la sélection
        </Button>
      </ButtonContainer>
    </CalendarContainer>
  )
}

export default ActivitiesCalendar
