/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useTheme } from '@emotion/react'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetProfileQuery,
  usePatchPasswordMutation,
  usePatchSettingsMutation,
  usePatchUserInfoMutation,
} from 'store/api'
import {
  isValidHexColor,
  isValidString,
} from 'helpers/isValidString'
import {
  INFO_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from 'theme/emotionTheme'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Button,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import { HexColorPicker } from 'react-colorful'
import RouteTitle from 'router/RouteTitle'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  UserInfoUpdateValidator,
  UserSettingsStoreValidator,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const infoSchema = Yup.object<Shape<UserInfoUpdateValidator>>({
  lastName: Yup.string().required('Le nom est obligatoire'),
  firstName: Yup.string().required('Le prénom est obligatoire'),
}).required()

type InfoForm = FormikContextType<UserInfoUpdateValidator>

interface Password {
  newPassword: string;
  newPasswordRepeat: string;
  oldPassword: string;
}

const passwordSchema = Yup.object<Shape<Password>>({
  newPassword: Yup.string(),
  newPasswordRepeat: Yup.string().when('newPassword', {
    is: (newPassword: string) => isValidString(newPassword),
    then: (schema) => schema.oneOf([ Yup.ref('newPassword') ], 'Les mots de passe ne correspondent pas').required('Les mots de passe ne correspondent pas'),
  }),
  oldPassword: Yup.string().when('newPassword', {
    is: (newPassword: string) => isValidString(newPassword),
    then: (schema) => schema.required('Votre ancien mot de passe est requis pour sa modification'),
  }),
}).required()

type PasswordForm = FormikContextType<Password>

const settingsSchema = Yup.object<Shape<UserSettingsStoreValidator>>({
  color1: Yup.string().test(
    'color-1',
    'La couleur est invalide',
    (value) => isValidHexColor(value),
  ),
  color2: Yup.string().test(
    'color-2',
    'La couleur est invalide',
    (value) => isValidHexColor(value),
  ),
  color3: Yup.string().test(
    'color-3',
    'La couleur est invalide',
    (value) => isValidHexColor(value),
  ),
  avatarColor: Yup.string().test(
    'avatarColor',
    'La couleur est invalide',
    (value) => isValidHexColor(value),
  ),
  monday: Yup.number().required('Ce champ est obligatoire').min(0, 'La durée doit etre comprise entre 0 et 10').max(10, 'La durée doit etre comprise entre 0 et 10'),
  tuesday: Yup.number().required('Ce champ est obligatoire').min(0, 'La durée doit etre comprise entre 0 et 10').max(10, 'La durée doit etre comprise entre 0 et 10'),
  wednesday: Yup.number().required('Ce champ est obligatoire').min(0, 'La durée doit etre comprise entre 0 et 10').max(10, 'La durée doit etre comprise entre 0 et 10'),
  thursday: Yup.number().required('Ce champ est obligatoire').min(0, 'La durée doit etre comprise entre 0 et 10').max(10, 'La durée doit etre comprise entre 0 et 10'),
  friday: Yup.number().required('Ce champ est obligatoire').min(0, 'La durée doit etre comprise entre 0 et 10').max(10, 'La durée doit etre comprise entre 0 et 10'),
}).required()

type SettingsForm = FormikContextType<UserSettingsStoreValidator>

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.15rem;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
  }
`

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const ColorContainer = styled(InfoContainer)`
  grid-template-columns: repeat(5, 1fr);

  .react-colorful {
    width: 100%;
    min-height: 315.5px;
    margin-bottom: 10px;

    @media ${(props) => props.theme.media.mobile.main} {
      min-height: 200px;
    }
  }
`

const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface ProfilePageProps {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
  const theme = useTheme()

  const {
    currentData: profile,
    isFetching: isFetchingProfile,
  } = useGetProfileQuery()
  const [
    submitUpdatePassword,
  ] = usePatchPasswordMutation()
  const [
    submitUpdateSettings,
  ] = usePatchSettingsMutation()
  const [
    submitUpdateUserInfo,
  ] = usePatchUserInfoMutation()

  const onSubmitInfo = (values: UserInfoUpdateValidator, { setSubmitting }: FormikHelpers<UserInfoUpdateValidator>): void => {
    submitUpdateUserInfo({ id: profile?.userInfo?.id || '', data: values }).then((response) => {
      setSubmitting(false)
      if (!isApiError(response)) {
        toast.success(`Vos informations ont bien été modifiées.`)
      } else {
        toast.error(`Une erreur est survenue lors de la mofication de vos informations.`)
      }
    }).catch(console.error)
  }

  const infoFormikForm: InfoForm = useForm<UserInfoUpdateValidator>(
    {
      initialValues: {
        lastName: '',
        firstName: '',
      },
      validationSchema: infoSchema,
      onSubmit: onSubmitInfo,
    },
  )

  const onSubmitPassword = (values: Password, { setSubmitting }: FormikHelpers<Password>): void => {
    submitUpdatePassword({
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
    }).then((response) => {
      setSubmitting(false)
      if (!isApiError(response)) {
        toast.success(`Votre mot de passe à bien été modifié.`)
      } else {
        toast.error(`Une erreur est survenue lors de la mofication de votre mot de passe. Veuillez vérifier votre ancien mot de passe.`)
      }
    }).catch(console.error)
  }

  const passwordFormikForm: PasswordForm = useForm<Password>(
    {
      initialValues: {
        newPassword: '',
        newPasswordRepeat: '',
        oldPassword: '',
      },
      validationSchema: passwordSchema,
      onSubmit: onSubmitPassword,
    },
  )

  const onSubmitSettings = (values: UserSettingsStoreValidator, { setSubmitting }: FormikHelpers<UserSettingsStoreValidator>): void => {
    submitUpdateSettings(values)
      .then((response) => {
        setSubmitting(false)
        if (!(isApiError(response))) {
          toast.success(`Vos paramètres ont bien été modifiés.`)
        } else {
          toast.error(`Une erreur est survenue lors de la mofication de vos paramètres.`)
        }
      }).catch(console.error)
  }

  const settingsFormikForm: SettingsForm = useForm<UserSettingsStoreValidator>(
    {
      initialValues: {
        color1: theme.palette.primary.main,
        color2: theme.palette.secondary.main,
        color3: theme.palette.info.main,
        avatarColor: '',
        monday: 8,
        tuesday: 8,
        wednesday: 8,
        thursday: 8,
        friday: 7,
      },
      validationSchema: settingsSchema,
      onSubmit: onSubmitSettings,
    },
  )

  useEffect(() => {
    if (!isFetchingProfile && profile) {
      profile.settings && settingsFormikForm.setValues(profile.settings)
      infoFormikForm.setValues({ firstName: profile.userInfo?.firstName, lastName: profile.userInfo?.lastName })
    }
  }, [ isFetchingProfile ])

  const randomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`

  return (
    <React.Fragment>
      <RouteTitle title="Mon compte" />
      <LargeTitle>
        Mon compte
      </LargeTitle>
      <Form form={infoFormikForm}>
        <CardContainer>
          <CardContent>
            <Title>
              Mes informations
              <Button
                variant="contained"
                type="submit"
                disabled={infoFormikForm.isSubmitting}
              >
                Modifier mes informations
              </Button>
            </Title>
            <InfoContainer>
              <div>
                <FormBoldTitle
                  smaller
                  required
                >
                  Prénom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Prénom"
                  name="firstName"
                  size="small"
                />
              </div>
              <div>
                <FormBoldTitle
                  smaller
                  required
                >
                  Nom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Nom"
                  name="lastName"
                  size="small"
                />
              </div>
            </InfoContainer>
          </CardContent>
        </CardContainer>
      </Form>
      <Form form={passwordFormikForm}>
        <CardContainer>
          <CardContent>
            <Title>
              Changer le mot de passe
              <Button
                variant="contained"
                type="submit"
                disabled={passwordFormikForm.isSubmitting}
              >
                Modifier le mot de passe
              </Button>
            </Title>
            <InfoContainer>
              <div>
                <FormBoldTitle smaller>
                  Ancien mot de passe
                </FormBoldTitle>
                <FormikPasswordInput
                  placeholder="Ancien mot de passe"
                  name="oldPassword"
                  size="small"
                  autoComplete="current-password"
                />
              </div>
              <div>
                <FormBoldTitle smaller>
                  Mot de passe
                </FormBoldTitle>
                <FormikPasswordInput
                  placeholder="Nouveau mot de passe"
                  name="newPassword"
                  size="small"
                  autoComplete="new-password"
                />
              </div>
              <div>
                <FormBoldTitle smaller>
                  Confirmer le mot de passe
                </FormBoldTitle>
                <FormikPasswordInput
                  placeholder="Confirmer le mot de passe"
                  name="newPasswordRepeat"
                  size="small"
                  autoComplete="new-password"
                />
              </div>
            </InfoContainer>
          </CardContent>
        </CardContainer>
      </Form>
      <Form form={settingsFormikForm}>
        <CardContainer>
          <CardContent>
            <Title>
              Paramètres
              <Button
                variant="contained"
                type="submit"
                disabled={settingsFormikForm.isSubmitting}
              >
                Valider les paramètres
              </Button>
            </Title>
            <ColorContainer>
              <DayContainer>
                <FormBoldTitle smaller>
                  Lundi
                </FormBoldTitle>
                <NumberField
                  placeholder="Lundi"
                  name="monday"
                  size="small"
                />
                <FormBoldTitle smaller>
                  Mardi
                </FormBoldTitle>
                <NumberField
                  placeholder="Mardi"
                  name="tuesday"
                  size="small"
                />
                <FormBoldTitle smaller>
                  Mercredi
                </FormBoldTitle>
                <NumberField
                  placeholder="Mercredi"
                  name="wednesday"
                  size="small"
                />
                <FormBoldTitle smaller>
                  Jeudi
                </FormBoldTitle>
                <NumberField
                  placeholder="Jeudi"
                  name="thursday"
                  size="small"
                />
                <FormBoldTitle smaller>
                  Vendredi
                </FormBoldTitle>
                <NumberField
                  placeholder="Vendredi"
                  name="friday"
                  size="small"
                />
              </DayContainer>
              <div>
                <FormBoldTitle smaller>
                  Couleur 1
                </FormBoldTitle>
                <HexColorPicker
                  color={settingsFormikForm.values.color1}
                  onChange={(e) => settingsFormikForm.setFieldValue('color1', e)}
                />
                <ButtonContainer>
                  <Field
                    component={TextField}
                    placeholder="Couleur 1"
                    name="color1"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={() => settingsFormikForm.setFieldValue('color1', PRIMARY_COLOR)}
                  >
                    Réinitialiser
                  </Button>
                </ButtonContainer>
              </div>
              <div>
                <FormBoldTitle smaller>
                  Couleur 2
                </FormBoldTitle>
                <HexColorPicker
                  color={settingsFormikForm.values.color2}
                  onChange={(e) => settingsFormikForm.setFieldValue('color2', e)}
                />
                <ButtonContainer>
                  <Field
                    component={TextField}
                    placeholder="Couleur 2"
                    name="color2"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={() => settingsFormikForm.setFieldValue('color2', SECONDARY_COLOR)}
                  >
                    Réinitialiser
                  </Button>
                </ButtonContainer>
              </div>
              <div>
                <FormBoldTitle smaller>
                  Couleur 3
                </FormBoldTitle>
                <HexColorPicker
                  color={settingsFormikForm.values.color3}
                  onChange={(e) => settingsFormikForm.setFieldValue('color3', e)}
                />
                <ButtonContainer>
                  <Field
                    component={TextField}
                    placeholder="Couleur 3"
                    name="color3"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={() => settingsFormikForm.setFieldValue('color3', INFO_COLOR)}
                  >
                    Réinitialiser
                  </Button>
                </ButtonContainer>
              </div>
              <div>
                <FormBoldTitle smaller>
                  Couleur de l'Avatar
                </FormBoldTitle>
                <HexColorPicker
                  color={settingsFormikForm.values.avatarColor}
                  onChange={(e) => settingsFormikForm.setFieldValue('avatarColor', e)}
                />
                <ButtonContainer>
                  <Field
                    component={TextField}
                    placeholder="Couleur Avatar"
                    name="avatarColor"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={() => settingsFormikForm.setFieldValue('avatarColor', randomColor())}
                  >
                    Aléatoire
                  </Button>
                </ButtonContainer>
              </div>
            </ColorContainer>
          </CardContent>
        </CardContainer>
      </Form>
    </React.Fragment>
  )
}

export default ProfilePage
