/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'
import PasswordInput from 'components/AuthComponents/PasswordInput'

/* Type imports ------------------------------------------------------------- */
import type { EnvironmentUrl } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`

/* Component declaration ---------------------------------------------------- */
interface EnvironmentUrlsTableMobileCardProps {
  environmentUrl: EnvironmentUrl;
  onDelete: () => void;
  onEdit: () => void;
}

const EnvironmentUrlsTableMobileCard: React.FC<EnvironmentUrlsTableMobileCardProps> = ({
  environmentUrl,
  onDelete,
  onEdit,
}) => {

  return (
    <CardContainer key={environmentUrl.id}>
      <CardContentContainer>
        <TitleContainer>
          {environmentUrl.url}
        </TitleContainer>
        {`Client : ${environmentUrl.client?.name || ''}`}
        <br />
        {`Projet : ${environmentUrl.project?.name || ''}`}
        <br />
        {`Env : ${environmentUrl.type?.name || ''}`}
        <br />
        {`Mot de passe :`}
        <PasswordInput
          value={environmentUrl.password || ''}
          disabled
          size="small"
          variant="standard"
        />
        <br />
        {`Base Euro : ${environmentUrl.euroBase || ''}`}
        <br />
        <ButtonContainer>
          <Button
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            onClick={onEdit}
          >
            Modifier
          </Button>
        </ButtonContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default EnvironmentUrlsTableMobileCard
