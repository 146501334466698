/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import socketIOClient from 'socket.io-client'

/* Module imports ----------------------------------------------------------- */
import {
  useLazyGetReviewBoardListQuery,
  usePostReviewBoardMutation,
} from 'store/api'
import { apiUrl } from 'helpers/readConfig'
import { useAuthInfo } from 'store/hooks'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  ClickAwayListener,
  Divider,
  Drawer,
  IconButton,
  List,
  MenuItem,
} from '@mui/material'
import {
  Close,
  Menu,
} from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import ReviewsBoard from './ReviewsComponents/ReviewsBoard'

/* Type imports ------------------------------------------------------------- */
import type { Socket } from 'socket.io-client'
import type { ReviewBoard } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const DrawerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
`

const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 400px;
    box-sizing: border-box;
  }
`

const Board = styled.div`
  width: 100%;
`

interface LayoutProps {
  open: boolean;
}

const Layout = styled.div<LayoutProps>`
  padding-left: ${(props) => props.open ? '400px' : '0px'};
  transition: padding-left .1s ease;

  @media ${(props) => props.theme.media.desktop} {
    padding-left: ${(props) => props.open ? `calc(400px - ${props.theme.layoutPadding.desktopSide}` : '0px'};
  }
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsPageProps {}

const ReviewsPage: React.FC<ReviewsPageProps> = () => {
  const auth = useAuthInfo()
  const [ socket, setSocket ] = useState<Socket>()
  const [ selectedBoardId, setSelectedBoardId ] = useState<number | false>(false)
  const [ openDrawer, setOpenDrawer ] = useState<boolean>(false)

  const [
    submitNewBoard,
    { isLoading: isSubmittingNewBoard },
  ] = usePostReviewBoardMutation()
  const [
    fetchBoardList,
    {
      currentData: boardList = [],
      isFetching: isFetchingBoardList,
    },
  ] = useLazyGetReviewBoardListQuery()

  const refechtBoardList = async () => {
    await fetchBoardList().then((response) => {
      if (response.data && !selectedBoardId) {
        setSelectedBoardId(response.data[0]?.id ?? null)
      }
    })
  }

  useEffect(() => {
    refechtBoardList()
    setSocket(socketIOClient(apiUrl, { transports: [ 'websocket' ], auth: { 'token': auth.token }}))
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on('boards:list', () => refechtBoardList())
    }

    return () => {
      socket && socket.off(`boards:list`)
    }
  }, [ socket ])

  const onNewBoardClick = async () => {
    await submitNewBoard({}).then((response) => {
      if (isApiResponse<ReviewBoard>(response)) {
        setSelectedBoardId(response.data.id)
      }
    })
  }

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  return (
    <Layout open={openDrawer}>
      <ClickAwayListener onClickAway={closeDrawer}>
        <div>
          <LargeTitle>
            <Title>
              <IconButton
                onClick={handleToggleDrawer}
                color="secondary"
              >
                <Menu />
              </IconButton>
              Reviews
            </Title>
            <ButtonsContainer>
              <LongButton
                variant="contained"
                onClick={onNewBoardClick}
                disabled={isFetchingBoardList || isSubmittingNewBoard}
              >
                Nouvelle review
              </LongButton>
            </ButtonsContainer>
          </LargeTitle>
          <DrawerContainer
            variant="persistent"
            anchor="left"
            open={openDrawer}
          >
            <DrawerTitle>
              Liste des Reviews
              <IconButton onClick={handleToggleDrawer}>
                <Close color="secondary" />
              </IconButton>
            </DrawerTitle>
            <Divider />
            <List>
              {
                boardList.map((board) => (
                  <React.Fragment key={board.id}>
                    <MenuItem
                      selected={board.id === selectedBoardId}
                      onClick={() => setSelectedBoardId(board.id)}
                      dense
                    >
                      {`${board.title} : ${board.rate}`}
                    </MenuItem>
                    <Divider />
                  </React.Fragment>
                ))
              }
            </List>
          </DrawerContainer>
        </div>
      </ClickAwayListener>
      <Board>
        {
          selectedBoardId && socket &&
            <ReviewsBoard
              boardId={selectedBoardId}
              socket={socket}
            />
        }
      </Board>
    </Layout>
  )
}

export default ReviewsPage
