/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import ImageHoverContainer from './ImageHoverContainer'
import VignetteRenderer from './VignetteRenderer'

/* Type imports ------------------------------------------------------------- */
import type { IncidentFile } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const FileName = styled.div`
  font-size: .8rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align-last: center;
`

/* Component declaration ---------------------------------------------------- */
interface VignetteRendererWithFilenameProps {
  document: IncidentFile;
  onClick: (document: IncidentFile) => void;
  width: string;
  height: string;
}

const VignetteRendererWithFilename: React.FC<VignetteRendererWithFilenameProps> = ({
  document,
  onClick,
  width,
  height,
}) => {

  return (
    <ImageHoverContainer onClick={() => onClick(document)}>
      <VignetteRenderer
        document={document}
        height={height}
        width={width}
      />
      <FileName>
        {document.name}
      </FileName>
    </ImageHoverContainer>
  )
}

export default VignetteRendererWithFilename
