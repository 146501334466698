/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useTheme } from '@emotion/react'
import { useGetProfileQuery } from 'store/api'
import { useAppDispatch } from 'store/hooks'
import { updateProfile } from 'store/slices/authSlice'

/* Component imports -------------------------------------------------------- */
import { Avatar as MuiAvatar } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { AvatarProps as MuiAvatarProps } from '@mui/material'

/* Type declarations -------------------------------------------------------- */
type Size = 'small' | 'medium' | 'large'

/* Styled components -------------------------------------------------------- */
const AvatarContainer = styled(MuiAvatar)<AvatarProps>`
  &:hover {
    cursor: ${(props) => String(props.button) === 'true' ? 'pointer' : 'default'};
    filter: ${(props) => String(props.button) === 'true' ? 'brightness(0.8)' : 'none'};
    transition: filter .1s ease-in;
  }
`

/* Component declaration ---------------------------------------------------- */
interface AvatarProps extends MuiAvatarProps {
  size?: Size;
  square?: boolean;
  button?: boolean;
  initials?: string;
  avatarColor?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  size = 'medium',
  square = false,
  initials,
  avatarColor,
  ...rest
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const { currentData: profile } = useGetProfileQuery(undefined, { skip: initials !== undefined })

  const sizes: {[x: string]: {width: number; height: number; fontSize: number} } = {
    'small': { width: 30, height: 30, fontSize: 16 },
    'medium': { width: 42, height: 42, fontSize: 22 },
    'large': { width: 56, height: 56, fontSize: 30 },
  }

  function textColor(bgColor: string) {
    const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
    const r = parseInt(color.substring(0, 2), 16) // hexToR
    const g = parseInt(color.substring(2, 4), 16) // hexToG
    const b = parseInt(color.substring(4, 6), 16) // hexToB
    const uicolors = [ r / 255, g / 255, b / 255 ]
    const c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92
      }
      return Math.pow((col + 0.055) / 1.055, 2.4)
    })
    const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2])
    return (L > 0.179) ? '#1a1625' : '#FFFFFF'
  }

  useEffect(() => {
    profile && dispatch(updateProfile(profile))
  }, [ profile ])

  const color = useMemo(() => avatarColor ?? profile?.settings?.avatarColor ?? theme.palette.primary.main, [ profile ])

  const name = useMemo(() => initials ? initials : profile?.userInfo?.initials, [ profile, initials ])

  return (
    <AvatarContainer
      {...rest}
      button={String(rest.button) as unknown as boolean}
      variant={square ? 'rounded': 'circular'}
      sx={
        {
          bgcolor: color,
          color: textColor(color),
          ...sizes[size],
        }
      }
    >
      {name}
    </AvatarContainer>
  )
}

export default Avatar
