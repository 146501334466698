/* Type imports ------------------------------------------------------------- */
import type { UserSettings } from 'API/__generated__/Api'

/* DateUtils methods -------------------------------------------------------- */
const isValidDate = (d: unknown): d is Date => {
  return d instanceof Date &&
    !Number.isNaN(d.valueOf())
}

const dateToAPIStr = (pDate: Date): string => {
  return pDate.toISOString()
}

const dateToShortAPIStr = (pDate: Date): string => {
  return dateToAPIStr(pDate)
    .split('T')[0]
}

const APIStrToDate = (pDateStr: string): Date => {
  return new Date(pDateStr)
}

const APIStrToShortDate = (pDateStr: string): string => {
  try {
    const date = new Date(pDateStr)
    if (!isValidDate(date)) {
      return pDateStr
    }
  } catch(error) {
    console.error(error)
    return pDateStr
  }

  return new Date(pDateStr).toISOString().split('T')[0]
}

const APIStrToDateOrUndefined = (pDateStr?: string | null): Date | undefined => {
  if (pDateStr === undefined || pDateStr === null) {
    return
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return
  }

  return date
}

const APIStrToLocalDateString = (pDateStr?: string | null, options?: Intl.DateTimeFormatOptions): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return date.toLocaleDateString('fr-FR', options)
}

const APIStrToLocalTimeString = (pDateStr?: string | null, options?: Intl.DateTimeFormatOptions): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) return ''

  return date.toLocaleTimeString('fr-FR', options)
}

const dateStringToAPILocalTimeString = (pDateStr?: string | null): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
}

const calendarDays: { [key: number]: string } = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
}

const defaultDaysHourCount: { [key: string]: number } = {
  monday: 8,
  tuesday: 8,
  wednesday: 8,
  thursday: 8,
  friday: 7,
  saturday: 0,
  sunday: 0,
}

const getCalendarHourAmount = (day: number, settings?: UserSettings) => (settings as unknown as typeof defaultDaysHourCount)?.[calendarDays[day]] ?? defaultDaysHourCount[calendarDays[day]]

/* Export DateUtils ------------------------------------ */
export default {
  isValidDate,
  dateToAPIStr,
  dateToShortAPIStr,
  APIStrToDate,
  APIStrToShortDate,
  APIStrToDateOrUndefined,
  APIStrToLocalDateString,
  APIStrToLocalTimeString,
  dateStringToAPILocalTimeString,
  getCalendarHourAmount,
}
