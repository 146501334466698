/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostEnvironmentUrlMutation,
  usePatchEnvironmentUrlMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import EnvironmentUrlsModalItem from './EnvironmentUrlsModalItem'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  EnvironmentUrlStoreValidator,
  Client,
  Project,
  EnvironmentType,
  EnvironmentUrl,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const environmentUrlBulkSchema = Yup.object<Shape<EnvironmentUrlStoreValidator>>({
  clientId: Yup.string().nullable(),
  projectId: Yup.string().nullable(),
  typeId: Yup.number().nullable(),
  url: Yup.string().required('Le lien est obligatoire'),
  password: Yup.string().nullable(),
  euroBase: Yup.string().nullable(),
}).required()

type EnvironmentUrlRequest = FormikContextType<EnvironmentUrlStoreValidator>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface EnvironmentUrlsModalProps {
  open: boolean;
  environmentUrl?: EnvironmentUrl;
  handleClose: () => void;
  clientList: Client[];
  isFetchingClientList: boolean;
  projectList: Project[];
  isFetchingProjectList: boolean;
  typeList: EnvironmentType[];
  isFetchingTypeList: boolean;
}

const EnvironmentUrlsModal: React.FC<EnvironmentUrlsModalProps> = ({
  open,
  handleClose,
  environmentUrl,
  ...props
}) => {

  const [
    submitNewEnvironmentUrl,
  ] = usePostEnvironmentUrlMutation()
  const [
    submitEditEnvironmentUrl,
  ] = usePatchEnvironmentUrlMutation()

  const onSubmit = (values: EnvironmentUrlStoreValidator, { setSubmitting, resetForm }: FormikHelpers<EnvironmentUrlStoreValidator>) => {
    if (environmentUrl) {
      submitEditEnvironmentUrl({ id: environmentUrl.id, data: values })
        .then((response) => {
          if (!isApiError(response)) {
            resetForm()
            handleClose()
          } else {
            toast.error('Une erreur est survenue.')
            setSubmitting(false)
          }
        }).catch(console.error)
    } else {
      submitNewEnvironmentUrl(values)
        .then((response) => {
          if (!isApiError(response)) {
            resetForm()
            handleClose()
          } else {
            toast.error('Une erreur est survenue.')
            setSubmitting(false)
          }
        }).catch(console.error)
    }
  }

  const formikForm: EnvironmentUrlRequest = useForm<EnvironmentUrlStoreValidator>(
    {
      initialValues: {
        url: '',
        typeId: null,
        clientId: '',
        projectId: '',
      },
      onSubmit: onSubmit,
      validationSchema: environmentUrlBulkSchema,
    },
  )

  useEffect(() => {
    if (environmentUrl) {
      formikForm.setValues(environmentUrl)
    }
  }, [ environmentUrl ])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          {environmentUrl ? 'Modifier ' : 'Ajouter ' }
          un environnement
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <EnvironmentUrlsModalItem {...props} />
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default EnvironmentUrlsModal
