/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  initialValues,
  type IncidentSchema,
} from 'pages/IncidentsPage/IncidentsPage'

/* Redux slice -------------------------------------------------------------- */
const incidentsFormSlice = createSlice(
  {
    name: 'incidentsForm',
    initialState: initialValues,
    reducers: {
      setIncidentsForm: (state, { payload }: PayloadAction<IncidentSchema>) => {
        return payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setIncidentsForm } = incidentsFormSlice.actions

export default incidentsFormSlice.reducer

export const getIncidentsForm = (state: RootState): IncidentSchema => {
  return state.incidentsForm
}
