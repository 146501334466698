/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Collapse,
} from '@mui/material'
import {
  AddRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'

/* Styled components -------------------------------------------------------- */
interface HoursProps {
  hours: number;
}

const CardContainer = styled(Card)<HoursProps>`
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.hours <= 10 ? props.theme.palette.secondary.main : props.theme.palette.error.main} !important;
`

const Title = styled.div<HoursProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px 5px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1rem;
  color: ${(props) => props.hours <= 10 ? props.theme.palette.secondary.main : props.theme.palette.error.main};
  cursor: pointer;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesModalProps {
  day: string;
  hours: number;
  children: React.ReactNode;
  onNewDayActivityClick: (day: string) => void;
}

const ActivitiesModalCard: React.FC<ActivitiesModalProps> = ({
  day,
  hours,
  children,
  onNewDayActivityClick,
}) => {
  const [ open, setOpen ] = useState<boolean>(true)

  return (
    <CardContainer hours={hours}>
      <Title
        onClick={() => setOpen(!open)}
        hours={hours}
      >
        {`${DateUtils.APIStrToLocalDateString(day, { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric' })} : ${hours}h`}
        <ButtonContainer>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={(e) => {e.stopPropagation(); onNewDayActivityClick(day)}}
          >
            <AddRounded />
          </Button>
          <DropDownArrow open={open} />
        </ButtonContainer>
      </Title>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </CardContainer>
  )
}

export default ActivitiesModalCard
