/* Module imports ----------------------------------------------------------- */
import { store } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { User } from 'API/__generated__/Api'
import type { Nullable } from 'types/Nullable'

/* isAllowed helper function ------------------------------------------------ */
interface Allowed {
  userId?: number;
  user?: Nullable<User>;
}

export const isAllowed = ({ userId, user }: Allowed): boolean => {
  const { isAdmin, id = 0 } = store.getState().auth

  if (user && user.id && user.isAdmin) {
    if (user.isAdmin) return true
    if (userId && user.id === userId) return true
    if (!userId && user.id === id) return true
  }
  else if (userId) {
    if (userId === id) return true
  }

  return isAdmin || false
}
