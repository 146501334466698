/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { ClientGroup } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 3 - 10px);

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
    margin-top: -15px;
  }

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / 3 - 10px);
  }
`

/* Component declaration ---------------------------------------------------- */
interface ClientsFiltersProps {
  groupList: ClientGroup[];
  isFetchingGroupList: boolean;
}

const ClientsFilters: React.FC<ClientsFiltersProps> = ({
  groupList,
  isFetchingGroupList,
}) => {

  return (
    <SelectFilterContainer>
      <Container>
        <FormBoldTitle>
          Groupe
        </FormBoldTitle>
        <AutocompleteField
          name="groupIds"
          options={groupList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => groupList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingGroupList}
          size="small"
          multiple
          disableAutoSelect
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Id
        </FormBoldTitle>
        <Field
          component={TextField}
          placeholder="Rechercher un client avec son Id"
          name="clientId"
          size="small"
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Nom
        </FormBoldTitle>
        <Field
          component={TextField}
          placeholder="Rechercher un client avec son nom"
          name="name"
          size="small"
        />
      </Container>
    </SelectFilterContainer>
  )
}

export default ClientsFilters
