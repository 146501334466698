/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import PodiumStep from './PodiumStep'

/* Type declarations -------------------------------------------------------- */
export interface Winner {
  id: number;
  name: string;
  avatarColor: string;
  place: number;
  initials: string;
  time: string;
}

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  display: grid;
  grid-auto-flow: column dense;
  gap: 10px;
  justify-content: center;
  justify-items: center;
  place-content: center;
  align-content: flex-end;
  align-items: flex-end;
  height: 80vh;
  padding-bottom: 50px;
`

/* Component declaration ---------------------------------------------------- */
interface PodiumProps {
  winners: Winner[];
}

const Podium: React.FC<PodiumProps> = ({ winners }) => {
  const podium = () => {
    const podiumOrder: Winner[] = []

    const evenWinners = winners.filter((_, index) => index % 2 === 0)
    const oddWinners = winners.filter((_, index) => index % 2 !== 0)

    evenWinners.reverse().forEach((winner) => {
      podiumOrder.push(winner)
    })

    oddWinners.forEach((winner) => {
      podiumOrder.push(winner)
    })

    return podiumOrder
  }

  return (
    <Container id="podium-container">
      {
        podium().map((winner, index) => (
          <PodiumStep
            key={winner.id}
            podium={podium()}
            winner={winner}
            index={index}
          />
        ))
      }
    </Container>
  )
}

export default Podium
