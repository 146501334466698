/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component declaration ---------------------------------------------------- */
interface MainLogoProps {}

const MainLogo: React.FC<MainLogoProps> = () => {

  return (
    <svg
      className="main-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width="100%"
      height="100%"
    >
      <g
        fill="none"
        fillRule="nonzero"
      >
        <g transform="scale(5.33333)">
          <path
            d="M16.414,39.586l-1.414,-1.414l-3.536,3.536c-0.391,0.391 -0.391,1.024 0,1.414v0c0.391,0.391 1.024,0.391 1.414,0z"
            fill="#8d49a9"
          />
          <path
            d="M32.172,36.586l1.414,-1.414l3.536,3.536c0.391,0.391 0.391,1.024 0,1.414v0c-0.391,0.391 -1.024,0.391 -1.414,0z"
            fill="#8d49a9"
          />
          <path
            d="M38,7.5v2.5h-3v-2.5c0,-0.828 0.672,-1.5 1.5,-1.5v0c0.828,0 1.5,0.672 1.5,1.5z"
            fill="#b178c9"
          />
          <path
            d="M31.691,7h2.309v15h-9v-8.309c0,-3.689 3.002,-6.691 6.691,-6.691z"
            fill="#68187e"
          />
          <path
            d="M6,2.473h3.75c2.899,0 5.25,2.351 5.25,5.25v0.75c0,2.25 -1.753,4.06 -3.112,4.42l-1.408,-0.833c-1.382,-0.818 -2.23,-2.306 -2.23,-3.912v-0.425c0,-1.5 -0.598,-4.69 -2.25,-5.25z"
            fill="#8d49a9"
          />
          <path
            d="M12.75,12.223c0,0 -2.25,-0.75 -2.25,-3c0,-2.25 0,-6 -4.5,-6.75c0,0 6.75,-0.75 6.75,6.75c0,0.75 0,3 0,3z"
            fill="#68187e"
          />
          <path
            d="M33.5,0l-2.5,10h5z"
            fill="#68187e"
          />
          <path
            d="M22,43c-8.271,0 -15,-6.729 -15,-15v-12c0,-2.762 2.239,-5 5,-5c2.761,0 5,2.238 5,5v12c0,2.757 2.243,5 5,5c2.757,0 5,-2.243 5,-5v-14c0,-2.762 2.239,-5 5,-5c2.761,0 5,2.238 5,5v14c0,8.271 -6.729,15 -15,15z"
            fill="#b178c9"
          />
          <path
            d="M37,19.734l-4.989,-1.734l4.989,0.011z"
            fill="#8d49a9"
          />
          <path
            d="M29.172,39.586l1.414,-1.414l3.536,3.536c0.391,0.391 0.391,1.024 0,1.414v0c-0.391,0.391 -1.024,0.391 -1.414,0z"
            fill="#b178c9"
          />
          <path
            d="M13.414,36.586l-1.414,-1.414l-3.536,3.536c-0.391,0.391 -0.391,1.024 0,1.414v0c0.391,0.391 1.024,0.391 1.414,0z"
            fill="#b178c9"
          />
          <path
            d="M32,7.5v2.5h-3v-2.5c0,-0.828 0.672,-1.5 1.5,-1.5v0c0.828,0 1.5,0.672 1.5,1.5z"
            fill="#b178c9"
          />
          <path
            d="M31.5,18h9c2.485,0 4.5,-2.015 4.5,-4.5v0c0,-2.485 -2.015,-4.5 -4.5,-4.5h-9c-2.485,0 -4.5,2.015 -4.5,4.5v0c0,2.485 2.015,4.5 4.5,4.5z"
            fill="#b178c9"
          />
          <circle
            cx="34"
            cy="13"
            r="2"
            fill="#ffffff"
          />
          <circle
            cx="35"
            cy="13"
            r="1"
            fill="#212121"
          />
          <path
            d="M44.96,14c-0.083,0.737 -0.34,1.417 -0.736,2h-0.224c-0.091,0 -2.261,-0.013 -4.447,-1.105l0.895,-1.789c1.748,0.874 3.536,0.894 3.552,0.894z"
            fill="#8d49a9"
          />
        </g>
      </g>
    </svg>
  )
}

export default MainLogo
