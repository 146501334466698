/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useDeleteProjectMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type { Project } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

const Error = styled.div`
  color: ${(props) => props.theme.palette.error.main};
`

/* Component declaration ---------------------------------------------------- */
interface ProjectsDeleteModalProps {
  open: boolean;
  handleClose: () => void;
  project: Project;
}

const ProjectsDeleteModal: React.FC<ProjectsDeleteModalProps> = ({
  open,
  handleClose,
  project,
}) => {
  const [ error, setError ] = useState<boolean>(false)

  const [
    submitDeleteProject,
  ] = useDeleteProjectMutation()

  const onDeleteClick = () => {
    setError(false)
    submitDeleteProject(project.id)
      .then((response) => {
        if (isApiError(response)) {
          setError(true)
        }
        else {
          handleClose()
        }
      })
      .catch(console.error)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        {`Suppression de ${project.name}`}
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {`Confirmez-vous la suppression de ${project.name} ? Cette action est irréversible.`}
        <br />
        Les imputations associés au projet seront également supprimées.
        <br />
        <br />
        {
          !error &&
        "Note: il n'est pas possible de supprimer un projet si des CRA ont été rentrés avec ce projet."
        }
        {
          error && (
            <Error>
              Il n'est pas possible de supprimer le projet car des CRA ont été rentrés avec ce projet.
            </Error>
          )
        }
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          variant="outlined"
          onClick={handleClose}
        >
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          color="error"
          onClick={onDeleteClick}
        >
          Supprimer
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ProjectsDeleteModal
