/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetClientListQuery,
  useGetIncidentListQuery,
  useGetIncidentAssignmentListQuery,
  useGetIncidentFamilyListQuery,
  useGetIncidentStatusListQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { exportToXLSX } from 'helpers/tableUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getIncidentsForm,
  setIncidentsForm,
} from 'store/slices/incidentsFormSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
  Pagination,
} from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Table from 'components/Table/Table'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import IncidentsFilters from './IncidentsComponents/IncidentsFilters'
import IncidentsTableMobileCard from './IncidentsComponents/IncidentsTableMobileCard'
import IncidentsDeleteModal from './IncidentsComponents/IncidentsDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'
import type {
  Incident,
  IncidentListParams,
} from 'API/__generated__/Api'
import type { ChipColor } from 'types/ChipColor'

/* Types declaration -------------------------------------------------------- */
const incidentsSchema = Yup.object({
  startDate: Yup.string(),
  endDate: Yup.string(),
  userIds: Yup.array(Yup.number().required()).nullable(),
  clientIds: Yup.array(Yup.string().required()).nullable(),
  familyIds: Yup.array(Yup.number().required()).nullable(),
  subFamilyIds: Yup.array(Yup.number().required()).nullable(),
  statusIds: Yup.array(Yup.string().required()).nullable(),
  orderBy: Yup.mixed<DataName>().required(),
  order: Yup.mixed<Order>().required(),
  page: Yup.number().required(),
  limit: Yup.number().required(),
}).required()

export type IncidentSchema = Yup.InferType<typeof incidentsSchema>

type IncidentForm = FormikContextType<IncidentSchema>

/* Internal Variables ------------------------------------------------------- */
const firstDay = new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0]
const lastDay = new Date(new Date().getFullYear(), 11, 32).toISOString().split('T')[0]

export const initialValues: IncidentSchema = {
  userIds: [],
  clientIds: [],
  familyIds: [],
  subFamilyIds: [],
  statusIds: [ 'Nouveau' ],
  order: 'desc',
  orderBy: 'createdAt',
  page: 1,
  limit: 10,
  startDate: firstDay,
  endDate: lastDay,
}

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const PriorityChip = styled(ColoredSquareChip)`
  width: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface IncidentsPageProps {}

const IncidentsPage: React.FC<IncidentsPageProps> = () => {
  const incidentsForm = useAppSelector(getIncidentsForm)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ deleteModal, setDeleteModal ] = useState<Incident | null>()

  const {
    currentData: userList = [],
    isFetching: isFetchingUserList,
  } = useGetIncidentAssignmentListQuery()
  const {
    currentData: familyList = [],
    isFetching: isFetchingFamilyList,
  } = useGetIncidentFamilyListQuery()
  const {
    currentData: statusList = [],
    isFetching: isFetchingStatusList,
  } = useGetIncidentStatusListQuery()
  const {
    currentData: clientList = [],
    isFetching: isFetchingClientList,
  } = useGetClientListQuery()

  const formikForm: IncidentForm = useForm<IncidentSchema>(
    {
      initialValues: incidentsForm ?? initialValues,
      validationSchema: incidentsSchema,
    },
  )

  useEffect(() => {
    dispatch(setIncidentsForm(formikForm.values))
  }, [ formikForm.values ])

  const {
    currentData: incidentPagination,
    isFetching: isFetchingIncidentPagination,
  } = useGetIncidentListQuery(formikForm.values as unknown as IncidentListParams)

  useEffect(() => {
    if (incidentPagination?.meta?.total !== undefined && incidentPagination?.meta?.total !== totalRows) {
      setTotalRows(incidentPagination?.meta?.total)
    }
  }, [ incidentPagination, isFetchingIncidentPagination ])

  const setOrder = (order: Order) => formikForm.setFieldValue('order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('orderBy', orderBy)
  const setLimit = (limit: number) => formikForm.setFieldValue('limit', limit)
  const setPage = (startIndex: number) => formikForm.setFieldValue('page', startIndex)

  const getPriorityColor = (code: string): ChipColor => {
    switch (code) {
      case 'Elevé':
        return 'red'
      case 'Confort':
        return 'green'
      default:
        return 'yellow'
    }
  }

  const cols: ColumnHeader[] = [
    {
      id: 'client.name',
      label: 'Client',
      sortId: 'clientId',
    },
    {
      id: 'family.name',
      label: 'Famille',
      sortId: 'familyId',
    },
    {
      id: 'comment',
      label: 'Description',
    },
    {
      id: 'createdAt',
      label: 'Date de création',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
    },
    {
      id: 'assignedTo.name',
      label: 'Affectation',
      sortId: 'userId',
    },
    {
      id: 'status.name',
      label: 'État',
      sortId: 'statusId',
    },
    {
      id: 'priority.name',
      label: 'Priorité',
      sortId: 'priorityId',
      render: (priority: string) => (
        <PriorityChip color={getPriorityColor(priority)}>
          {priority}
        </PriorityChip>
      ),
    },
  ]

  const exportDataToXLSX = () => {
    incidentPagination?.data && exportToXLSX(cols.filter((col) => !col.removeFromExport), incidentPagination.data, 'Incidents')
  }

  const onEditIncident = (id: number) => {
    navigate(`${id}`)
  }

  const getSubFamilyList = () => {
    const subFamilL = familyList.filter((f) => formikForm.values.familyIds?.includes(f.id)).flatMap((f) => f.subFamilies ?? [])
    const subFamilyIds: number[] = []
    let editArray: boolean = false

    formikForm.values.subFamilyIds?.forEach((f) => {
      if (subFamilL.find((s) => s.id === f)) {
        subFamilyIds.push(f)
      } else {
        editArray = true
      }
    })

    if (editArray) {
      formikForm.setFieldValue('subFamilyIds', subFamilyIds)
    }

    return subFamilL
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Incidents
        <ButtonsContainer>
          <LongButton
            variant="outlined"
            onClick={() => exportDataToXLSX()}
          >
            Exporter
          </LongButton>
          <LongButton
            variant="contained"
            onClick={() => navigate('/incident/nouveau')}
          >
            Nouvel incident
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <FilterDesktopContainer>
        <IncidentsFilters
          userList={userList}
          isFetchingUserList={isFetchingUserList}
          clientList={clientList}
          isFetchingClientList={isFetchingClientList}
          familyList={familyList}
          isFetchingFamilyList={isFetchingFamilyList}
          statusList={statusList}
          isFetchingStatusList={isFetchingStatusList}
          subFamilyList={getSubFamilyList()}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <IncidentsFilters
            userList={userList}
            isFetchingUserList={isFetchingUserList}
            clientList={clientList}
            isFetchingClientList={isFetchingClientList}
            familyList={familyList}
            isFetchingFamilyList={isFetchingFamilyList}
            statusList={statusList}
            isFetchingStatusList={isFetchingStatusList}
            subFamilyList={getSubFamilyList()}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      <LoadingOverlay isLoading={isFetchingIncidentPagination}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={incidentPagination?.data ?? []}
              setRows={() => null}
              colHeaders={cols}
              defaultOrder={formikForm.initialValues}
              onRowClick={(row) => onEditIncident((row as Incident).id)}
              limit={formikForm.initialValues.limit}
              sorting={{ setOrder, setOrderBy }}
              pagination={{ totalRows, setLimit, setPage }}
              resultsPerPage={[ 10, 25, 50, 100, totalRows ?? 200 ]}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          <Pagination
            count={incidentPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
          {
            incidentPagination?.data?.map((incident, index) => (
              <IncidentsTableMobileCard
                key={`${incident.id}-${index}`}
                incident={incident}
                onEdit={() => onEditIncident(incident.id)}
                onDelete={() => setDeleteModal(incident)}
              />
            ))
          }
          <Pagination
            count={incidentPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
        </MobileCardContainer>
      </LoadingOverlay>
      {
        deleteModal &&
          <IncidentsDeleteModal
            open
            incident={deleteModal}
            handleClose={() => setDeleteModal(null)}
          />
      }
    </Form>
  )
}

export default IncidentsPage
