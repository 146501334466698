/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { Incident } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`

/* Component declaration ---------------------------------------------------- */
interface IncidentsTableMobileCardProps {
  incident: Incident;
  onDelete: () => void;
  onEdit: () => void;
}

const IncidentsTableMobileCard: React.FC<IncidentsTableMobileCardProps> = ({
  incident,
  onDelete,
  onEdit,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          {DateUtils.APIStrToLocalDateString(incident.createdAt, { hour: '2-digit', minute: '2-digit' })}
        </TitleContainer>
        {`Client : ${incident.client?.name || ''}`}
        <br />
        {`Famille : ${incident.family?.name || ''}`}
        <br />
        {`Description : ${incident.comment || ''}`}
        <br />
        {`Affectation : ${incident.assignedTo?.name || ''}`}
        <br />
        {`État : ${incident.status?.name || ''}`}
        <br />
        <ButtonContainer>
          <Button
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            onClick={onEdit}
          >
            Modifier
          </Button>
        </ButtonContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default IncidentsTableMobileCard
