/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useDeleteReviewBoardCategoryMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsCategoryDeleteModalProps {
  handleClose: () => void;
  name: string;
  id: number;
  reviewBoardId: number;
}

const ReviewsCategoryDeleteModal: React.FC<ReviewsCategoryDeleteModalProps> = ({
  handleClose,
  name,
  id,
  reviewBoardId,
}) => {

  const [
    submitDeleteReviewCategory,
  ] = useDeleteReviewBoardCategoryMutation()

  const onDeleteClick = () => {
    submitDeleteReviewCategory({ id, reviewBoardId })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        {`Suppression de ${name}`}
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {`Confirmez-vous la suppression de ${name} ? Cette action est irréversible et supprimera toutes les cartes de cette categorie.`}
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          variant="outlined"
          onClick={handleClose}
        >
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          color="error"
          onClick={onDeleteClick}
        >
          Supprimer
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ReviewsCategoryDeleteModal
