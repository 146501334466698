/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  Client,
  Project,
  EnvironmentType,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 3 - 10px);

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
    margin-top: -15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface EnvironmentUrlsFiltersProps {
  projectList: Project[];
  isFetchingProjectList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  typeList: EnvironmentType[];
  isFetchingTypeList: boolean;
}

const EnvironmentUrlsFilters: React.FC<EnvironmentUrlsFiltersProps> = ({
  projectList,
  isFetchingProjectList,
  typeList,
  isFetchingTypeList,
  clientList,
  isFetchingClientList,
}) => {

  const getFullName = (value?: Client | Project) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <SelectFilterContainer>
      <Container>
        <FormBoldTitle>
          Projet
        </FormBoldTitle>
        <AutocompleteField
          name="projectIds"
          options={projectList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => getFullName(projectList.find((p) => p.id === option))}
          disabled={isFetchingProjectList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Client
        </FormBoldTitle>
        <AutocompleteField
          name="clientIds"
          options={clientList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
          disabled={isFetchingClientList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Statut
        </FormBoldTitle>
        <AutocompleteField
          name="typeIds"
          options={typeList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => typeList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingTypeList}
          size="small"
          multiple
        />
      </Container>
    </SelectFilterContainer>
  )
}

export default EnvironmentUrlsFilters
