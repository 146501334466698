/* eslint-disable @typescript-eslint/ban-ts-comment */
/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetClientListQuery,
  useGetIncidentAssignmentListQuery,
  useGetIncidentFamilyListQuery,
  useGetIncidentStatusListQuery,
  useGetIncidentPriorityListQuery,
  usePostIncidentFilesMutation,
  usePostIncidentMutation,
} from 'store/api'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  IconButton,
} from '@mui/material'
import { DeleteRounded } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import VignetteRenderer from 'components/MediaRenderer/VignetteRenderer'
import LoadingOverlay from 'components/Loader/LoadingOverlay'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  Incident,
  IncidentStoreValidator,
  Client,
  IncidentFileStoreValidator,
} from 'API/__generated__/Api'
import type { Shape } from 'components/FormikLogic/FormikLogic'

/* Types declaration -------------------------------------------------------- */
const incidentSchema = Yup.object<Shape<IncidentStoreValidator>>({
  clientId: Yup.string().nullable().required('Ce champ est obligatoire'),
  comment: Yup.string(),
  statusId: Yup.string().nullable().required('Ce champ est obligatoire'),
  ticketUrl: Yup.string(),
  contact: Yup.string().required('Ce champ est obligatoire'),
  familyId: Yup.number().nullable().required('Ce champ est obligatoire'),
  subFamilyId: Yup.number().nullable().required('Ce champ est obligatoire'),
  priorityId: Yup.number().nullable().required('Ce champ est obligatoire'),
  userId: Yup.number().nullable().required('Ce champ est obligatoire'),
}).required()

type StoreIncidentRequest = FormikContextType<IncidentStoreValidator>

/* Styled components -------------------------------------------------------- */
const ColContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-start;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 3 - 10px);
  margin-top: -15px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / 3 - 10px);
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.2rem;
`

const GridContainer = styled.div`
  margin-top: 10px;
  display: grid;
  gap: 30px;
  align-items: stretch;
  justify-content: start;

  grid-template-columns: repeat(auto-fit, 100px);

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: repeat(auto-fit, 90px);
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const DeleteButtonContainer = styled(IconButton)`
  position: absolute;
  right: 2px;
  top: 95px;
  z-index: 10;
  color: ${(props) => props.theme.palette.error.main};
  background-color: rgba(255, 255, 255, 0.225);
`

/* Component declaration ---------------------------------------------------- */
interface NewIncidentPageProps {}

const NewIncidentPage: React.FC<NewIncidentPageProps> = () => {
  const navigate = useNavigate()
  const [ loading, setIsLoading ] = useState<boolean>(false)
  const [ files, setFiles ] = useState<File[]>([])

  const {
    currentData: userList = [],
    isFetching: isFetchingUserList,
  } = useGetIncidentAssignmentListQuery()
  const {
    currentData: familyList = [],
    isFetching: isFetchingFamilyList,
  } = useGetIncidentFamilyListQuery()
  const {
    currentData: statusList = [],
    isFetching: isFetchingStatusList,
  } = useGetIncidentStatusListQuery()
  const {
    currentData: priorityList = [],
    isFetching: isFetchingPriorityList,
  } = useGetIncidentPriorityListQuery()
  const {
    currentData: clientList = [],
    isFetching: isFetchingClientList,
  } = useGetClientListQuery()
  const [
    submitCreateIncident,
  ] = usePostIncidentMutation()
  const [
    submitNewFiles,
  ] = usePostIncidentFilesMutation()

  const formikForm: StoreIncidentRequest = useForm<IncidentStoreValidator>(
    {
      initialValues: {
        accountedAt: '',
        clientId: '',
        clientRelaunch: false,
        comment: '- Numéro de dossier / Facture / Avoir : \n- Document(s) / Fichier(s) affecté(s) : \n- Motif de la demande : ',
        contact: '',
        statusId: 'Nouveau',
        ticketUrl: '',
        priorityId: 1,
        // @ts-ignore
        familyId: null,
        // @ts-ignore
        subFamilyId: null,
        // @ts-ignore
        userId: 1,
      },
      validationSchema: incidentSchema,
    },
  )

  const onCreateIncident = () => {
    setIsLoading(true)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitCreateIncident({ data: formikForm.values })
      })
      .then((response) => {
        if (!response) return
        if (isApiError(response)) {
          toast.error("Une erreur est survenue lors de l'enregistrement de l'incident.")
          formikForm.setSubmitting(false)
        } else if (isApiResponse<Incident>(response)) {
          if (files.length === 0) {
            navigate(-1)
            return
          }
          const data: IncidentFileStoreValidator = { files: []}

          files.forEach((file) => {
            file && data.files.push(file)
          })

          return submitNewFiles({ incidentId: response.data.id, data })
        }
      })
      .then((response) => {
        if (isApiError(response)) {
          toast.error("Une erreur est survenue lors de l'ajout de vos pièces jointes.")
          formikForm.setSubmitting(false)
        } else if (response) {
          navigate(-1)
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const getSubFamilyList = () => {
    const subFamilL = familyList.find((f) => formikForm.values.familyId === f.id)?.subFamilies ?? []

    if (formikForm.values.subFamilyId && !subFamilL.find((s) => s.id === formikForm.values.subFamilyId)) {
      formikForm.setFieldValue('subFamilyId', '')
    }

    return subFamilL
  }

  const getFullName = (value?: Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...files, ...Object.values(e.target.files) ])
    }
  }

  const onFileDeleteClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setFiles(files.filter((v, i) => i !== index))
  }

  return (
    <Form form={formikForm}>
      <RouteTitle title="Nouvel Incident" />
      <LargeTitle>
        Nouvel Incident
        <ButtonsContainer>
          <LongButton
            variant="contained"
            onClick={onCreateIncident}
            disabled={loading}
          >
            Valider
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <LoadingOverlay isLoading={loading}>
        <Card>
          <CardContent>
            <Title>
              Informations
            </Title>
            <ColContainer>
              <Container>
                <FormBoldTitle required>
                  Client
                </FormBoldTitle>
                <AutocompleteField
                  name="clientId"
                  options={clientList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
                  disabled={isFetchingClientList}
                  size="small"
                  sort
                />
                <FormBoldTitle required>
                  Personne à contacter
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Personne à contacter"
                  name="contact"
                  size="small"
                />
                <FormBoldTitle required>
                  Affectation
                </FormBoldTitle>
                <AutocompleteField
                  name="userId"
                  options={userList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => userList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingUserList}
                  size="small"
                />
                <FormBoldTitle required>
                  État
                </FormBoldTitle>
                <AutocompleteField
                  name="statusId"
                  options={statusList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => statusList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingStatusList}
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle required>
                  Famille
                </FormBoldTitle>
                <AutocompleteField
                  name="familyId"
                  options={familyList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => familyList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingFamilyList}
                  size="small"
                  sort
                />
                <FormBoldTitle required>
                  Sous-Famille
                </FormBoldTitle>
                <AutocompleteField
                  name="subFamilyId"
                  options={getSubFamilyList().filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => getSubFamilyList().find((p) => p.id === option)?.name || ''}
                  disabled={getSubFamilyList().length === 0}
                  size="small"
                  sort
                />
                <FormBoldTitle required>
                  Priorité
                </FormBoldTitle>
                <AutocompleteField
                  name="priorityId"
                  options={priorityList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => priorityList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingPriorityList}
                  size="small"
                />
                <FormBoldTitle>
                  Lien Mantis
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Lien Mantis"
                  name="ticketUrl"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle>
                  Description
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Description"
                  name="comment"
                  size="small"
                  multiline
                  rows={5.1}
                />
              </Container>
            </ColContainer>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Title>
              Pièces-jointes
              <AttachmentButton onChange={handleOnFileChange}>
                <Button
                  variant="contained"
                  component="span"
                >
                  Ajouter une pièce jointe
                </Button>
              </AttachmentButton>
            </Title>
            <GridContainer>
              {
                files?.map((file, index) => (
                  <ImageContainer key={`${file.name}-${index}`}>
                    <VignetteRenderer
                      file={file}
                      width="100%"
                      height="130px"
                    />
                    <DeleteButtonContainer
                      onClick={onFileDeleteClick(index)}
                      size="small"
                    >
                      <DeleteRounded />
                    </DeleteButtonContainer>
                  </ImageContainer>
                ))
              }
            </GridContainer>
          </CardContent>
        </Card>
      </LoadingOverlay>
    </Form>
  )
}

export default NewIncidentPage
