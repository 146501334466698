/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  Client,
  IncidentAssignment,
  IncidentStatus,
  IncidentFamily,
  IncidentSubFamily,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const Container = styled.div`
  width: calc(100% / 7 - 10px);

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
    margin-top: -15px;
  }

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / 3 - 10px);
  }
`

const DatePicker = styled(FormikDatePicker)`
  .MuiInputBase-root {
    height: 40px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface IncidentsFiltersProps {
  statusList: IncidentStatus[];
  isFetchingStatusList: boolean;
  clientList: Client[];
  isFetchingClientList: boolean;
  userList: IncidentAssignment[];
  isFetchingUserList: boolean;
  familyList: IncidentFamily[];
  isFetchingFamilyList: boolean;
  subFamilyList: IncidentSubFamily[];
}

const IncidentsFilters: React.FC<IncidentsFiltersProps> = ({
  userList,
  isFetchingUserList,
  clientList,
  isFetchingClientList,
  statusList,
  isFetchingStatusList,
  familyList,
  isFetchingFamilyList,
  subFamilyList,
}) => {

  const getFullName = (value?: Client) => {
    if (!value) return ''
    return `${value.name} (${value.id})`
  }

  return (
    <SelectFilterContainer>
      <Container>
        <FormBoldTitle>
          Client
        </FormBoldTitle>
        <AutocompleteField
          name="clientIds"
          options={clientList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => getFullName(clientList.find((p) => p.id === option))}
          disabled={isFetchingClientList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Famille
        </FormBoldTitle>
        <AutocompleteField
          name="familyIds"
          options={familyList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => familyList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingFamilyList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Sous-Famille
        </FormBoldTitle>
        <AutocompleteField
          name="subFamilyIds"
          options={subFamilyList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => subFamilyList.find((p) => p.id === option)?.name || ''}
          disabled={subFamilyList.length === 0}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Affecté à
        </FormBoldTitle>
        <AutocompleteField
          name="userIds"
          options={userList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => userList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingUserList}
          size="small"
          multiple
        />
      </Container>
      <Container>
        <FormBoldTitle>
          Date de début
        </FormBoldTitle>
        <DatePicker name="startDate" />
      </Container>
      <Container>
        <FormBoldTitle>
          Date de fin
        </FormBoldTitle>
        <DatePicker name="endDate" />
      </Container>
      <Container>
        <FormBoldTitle>
          État
        </FormBoldTitle>
        <AutocompleteField
          name="statusIds"
          options={statusList.filter((p) => p.name).map((p) => p.id)}
          getOptionLabel={(option) => statusList.find((p) => p.id === option)?.name || ''}
          disabled={isFetchingStatusList}
          size="small"
          multiple
        />
      </Container>
    </SelectFilterContainer>
  )
}

export default IncidentsFilters
