/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetAllUserInfoListQuery,
  useGetUserInfoPaginatedQuery,
} from 'store/api'
import { exportToXLSX } from 'helpers/tableUtils'
import {
  useAppDispatch,
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import {
  getUsersForm,
  setUsersForm,
} from 'store/slices/usersFormSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
  Pagination,
} from '@mui/material'
import {
  Check,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Table from 'components/Table/Table'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import UsersFilters from './UsersComponents/UsersFilters'
import UsersTableMobileCard from './UsersComponents/UsersTableMobileCard'
import UsersNewModal from './UsersComponents/UsersNewModal'
import UsersEditModal from './UsersComponents/UsersEditModal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'
import type {
  IndexPaginatedUserInfoListParams,
  UserInfo,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const usersSchema = Yup.object({
  search: Yup.string(),
  disabled: Yup.boolean(),
  orderBy: Yup.mixed<DataName>().required(),
  order: Yup.mixed<Order>().required(),
  page: Yup.number().required(),
  limit: Yup.number().required(),
}).required()

export type UserSchema = Yup.InferType<typeof usersSchema>

type UserForm = FormikContextType<UserSchema>

/* Internal Variables ------------------------------------------------------- */
export const initialValues: UserSchema = {
  search: '',
  disabled: false,
  order: 'asc',
  orderBy: 'id',
  page: 1,
  limit: 25,
}

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

/* Component declaration ---------------------------------------------------- */
interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = () => {
  const authInfo = useAuthInfo()
  const usersForm = useAppSelector(getUsersForm)
  const dispatch = useAppDispatch()
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ openNewModal, setOpenNewModal ] = useState<boolean>(false)
  const [ editModal, setEditModal ] = useState<string | null>(null)

  const {
    currentData: userInfoList = [],
    isFetching: isFetchingUserInfoList,
  } = useGetAllUserInfoListQuery()

  const formikForm: UserForm = useForm<UserSchema>(
    {
      initialValues: usersForm ?? initialValues,
      validationSchema: usersSchema,
    },
  )

  useEffect(() => {
    dispatch(setUsersForm(formikForm.values))
  }, [ formikForm.values ])

  const {
    currentData: userPagination,
    isFetching: isFetchingUserPagination,
  } = useGetUserInfoPaginatedQuery(formikForm.values as unknown as IndexPaginatedUserInfoListParams)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userPagination?.meta?.total !== undefined &&userPagination?.meta?.total !== totalRows) {
        setTotalRows(userPagination?.meta?.total)
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [ userPagination, isFetchingUserPagination ])

  const setOrder = (order: Order) => formikForm.setFieldValue('order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('orderBy', orderBy)
  const setLimit = (limit: number) => formikForm.setFieldValue('limit', limit)
  const setPage = (startIndex: number) => formikForm.setFieldValue('page', startIndex)

  const cols: ColumnHeader[] = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'firstName',
      label: 'Prénom',
    },
    {
      id: 'lastName',
      label: 'Nom',
    },
    {
      id: 'email',
      label: 'Email',
    },
    // {
    //   id: 'user.isAdmin',
    //   label: 'Admin ?',
    //   render: (isAdmin: boolean) => isAdmin && <Check />,
    //   renderForExport: (isAdmin: boolean) => isAdmin ? '✓' : '',
    // },
    {
      id: 'user.email',
      label: 'Compte Unlimited',
      render: (value: string) => value && <Check />,
      renderForExport: (value: string) => value ? '✓' : '',
    },
    {
      id: 'disabled',
      label: 'Statut',
      render: (disabled: boolean) => disabled ? 'Désactivé' : 'Actif',
      renderForExport: (disabled: boolean) => disabled ? 'Désactivé' : 'Actif',
    },
  ]

  const exportDataToXLSX = () => {
    userPagination?.data && exportToXLSX(cols.filter((col) => !col.removeFromExport), userPagination.data, 'Collaborateurs')
  }

  const onEditUser = (id: string) => {
    setEditModal(id)
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Collaborateurs
        <ButtonsContainer>
          <LongButton
            variant="outlined"
            onClick={exportDataToXLSX}
          >
            Exporter
          </LongButton>
          {
            authInfo.isAdmin &&
              <LongButton
                variant="contained"
                onClick={() => setOpenNewModal(true)}
                disabled={isFetchingUserInfoList}
              >
                Nouveau collaborateur
              </LongButton>
          }
        </ButtonsContainer>
      </LargeTitle>
      <FilterDesktopContainer>
        <UsersFilters
          disabled={formikForm.values.disabled || false}
          setDisabled={(value) => formikForm.setFieldValue('disabled', value)}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <UsersFilters
            disabled={formikForm.values.disabled || false}
            setDisabled={(value) => formikForm.setFieldValue('disabled', value)}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      <LoadingOverlay isLoading={isFetchingUserPagination}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={userPagination?.data ?? []}
              setRows={() => null}
              colHeaders={cols}
              defaultOrder={formikForm.initialValues}
              onRowClick={(row) => authInfo.isAdmin && onEditUser((row as UserInfo).id)}
              limit={formikForm.initialValues.limit}
              sorting={{ setOrder, setOrderBy }}
              pagination={{ totalRows, setLimit, setPage }}
              resultsPerPage={[ 10, 25, 50, 100, totalRows ?? 200 ]}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          <Pagination
            count={userPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
          {
            userPagination?.data?.map((user, index) => (
              <UsersTableMobileCard
                key={`${user.id}-${index}`}
                userInfo={user}
                onEdit={() => onEditUser(user.id)}
              />
            ))
          }
          <Pagination
            count={userPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
        </MobileCardContainer>
      </LoadingOverlay>
      {
        openNewModal &&
          <UsersNewModal
            open
            userInfoList={userInfoList}
            handleClose={() => setOpenNewModal(false)}
          />
      }
      {
        editModal &&
          <UsersEditModal
            open
            id={editModal}
            userInfoList={userInfoList}
            handleClose={() => setEditModal(null)}
          />
      }
    </Form>
  )
}

export default UsersPage
